import React, { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from 'react-router-dom';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import spinnerSvc from './utils/spinner-service';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthUser, setIsSelected } from './features/Auth Slice/authSlice';
import LoaderSpinner from './components/Loader/Loader';
import { RootState } from './store/store';
import Conversation from './screens/Chat/Conversation';
import CustomerScreen from './screens/CustomerScreen/CustomerScreen';
import HallsScreen from './screens/HallScreen/HallsScreen';
import BookingScreen from './screens/BookingsScreen/BookingScreen';
import SignUpScreen from './screens/SignupScreen/SignUpScreen';
import VanueDetailModal from './screens/VanueDetailModal/VanueDetailModal';
import NewBanquetBooking from './screens/NewBanquet/NewBanquetBooking';
import BanquetScreen from './screens/BanquetScreen/BanquetScreen';
import NewVenue from './screens/NewVenues/NewVenue';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhotographyScreen from './screens/PhotographyScreen/Photography';
import SaloonScreen from './screens/SaloonScreen/Saloon';
import ResetPassword from './screens/ResetPassword/ResetPassword';
function App() {
  const selectedRole = localStorage.getItem('selectedRole');
  const [loading, setLoading] = useState(null);
  const { User, isSelected } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const subscription = spinnerSvc.requestInProgress.subscribe((data: any) => {
      setLoading(data);
    });

    const searchParams = new URLSearchParams(window.location.search);
    const mode = searchParams.get('mode');
    const actionCode = searchParams.get('oobCode');
    const continueUrl = searchParams.get('continueUrl') || '/';
    const lang = searchParams.get('lang') || 'en';

    switch (mode) {
      case 'resetPassword':
        console.log('search', searchParams);
        window.location.replace(
          `http://localhost:3000/reset?&actionCode=${actionCode}`
        );
        // <Navigate to='/reset' state={{actionCode:actionCode,continueUrl:continueUrl}}/>
        // handleResetPassword(auth, actionCode, continueUrl, lang);
        break;
      case 'recoverEmail':
        // handleRecoverEmail(auth, actionCode, lang);
        break;
      case 'verifyEmail':
        window.location.replace(
          `http://localhost:3000/login?&actionCode=${actionCode}`
        );
        // navigate('/verifyEmail', {state:{actionCode:actionCode,continueUrl:continueUrl}})
        // handleVerifyEmail(auth, actionCode, continueUrl, lang);
        break;
      default:
        console.log('Break');
        break;
    }

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const user = JSON.parse(localStorage.getItem('user')!);
  useEffect(() => {
    if (!User?.id && user) {
      dispatch(setAuthUser(user));
      dispatch(setIsSelected(selectedRole));
    }
  }, [User]);

  console.log('selectedRole', selectedRole);
  const PrivateRoutesForAdmin = () => {
    return selectedRole === 'admin' ? <Outlet /> : <Navigate to="/" />;
  };

  const PrivateRoutesForCustomer = () => {
    return selectedRole === 'customer' ? <Outlet /> : <Navigate to="/" />;
  };

  const PrivateRoutesForBoth = () => {
    return user?.role?.includes(selectedRole) ? (
      <Outlet />
    ) : (
      <Navigate to="/" />
    );
  };

  return (
    <>
      {/* {loading && <LoaderSpinner/>} */}

      <Router>
        <Routes>
          <Route path="/halls" element={<HallsScreen />} />
          {selectedRole != '' && (
            <Route path="/bookings" element={<BookingScreen />} />
          )}
          <Route element={<PrivateRoutesForBoth />}>
            <Route path="/Conversation" element={<Conversation />} />
          </Route>
          <Route
            path="/"
            element={
              selectedRole === 'admin' ? <BanquetScreen /> : <CustomerScreen />
            }
          />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/signup" element={<SignUpScreen />} />
          <Route path="/VanueModal" element={<VanueDetailModal />} />
          <Route path="/NewBanquetBooking" element={<NewBanquetBooking />} />
          <Route path="/Salon" element={<SaloonScreen />} />
          <Route path="/Photography" element={<PhotographyScreen />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/NewVenue" element={<NewVenue />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
