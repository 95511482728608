import { Button, Dropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { CiLogout } from 'react-icons/ci';
import { CgProfile } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout, setUser } from '../../service/auth.service';

interface IProps {
  banquetId?: string;
  banquetName?: string;
}

const SecondaryHeader = (props: IProps) => {
  const user = useSelector((state: RootState) => state.auth.User);
  const location = useLocation();

  const navigation = useNavigate();

  const handleChat = () => {
    if (props.banquetId && props.banquetName) {
      navigation('/Conversation', {
        state: { banquetId: props.banquetId, banquetName: props.banquetName },
      });
    } else {
      navigation('/Conversation');
    }
  };

  return (
    <>
      <header className="SecondaryHeader">
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
          <div className="px-4 py-3 d-flex justify-content-between align-items-center w-100">
            <Navbar.Brand href="#home">
              <img src="/images/weblogo.svg" alt="Web logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link
                  as={Link}
                  to="/"
                  className={`fw-semibold ${
                    location.pathname === '/' ? 'active' : ''
                  }`}
                  // href="/"
                >
                  Banquets
                </Nav.Link>
                {user?.isSelectedRole !== 'admin' ? (
                  <>
                    {/* <Nav.Link
                      as={Link}
                      to="/Wellness"
                      className={`fw-semibold ${
                        location.pathname === '/Wellness' ? 'active' : ''
                      }`}
                    >
                      Welness
                    </Nav.Link> */}
                    <Nav.Link
                      as={Link}
                      to="/Photography"
                      className={`fw-semibold ${
                        location.pathname === '/Photography' ? 'active' : ''
                      }`}
                    >
                      Studios
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/Salon"
                      className={`fw-semibold ${
                        location.pathname === '/Salon' ? 'active' : ''
                      }`}
                    >
                      Salons
                    </Nav.Link>
                  </>
                ) : null}
              </Nav>
              <Nav className="gap-3 d-flex align-items-center">
                {user?.isSelectedRole === 'customer' && (
                  <Nav.Link className="fw-semibold" href={'/bookings'}>
                    My Bookings
                  </Nav.Link>
                )}
                <Nav.Link
                  className="fw-semibold"
                  eventKey={2}
                  onClick={() => handleChat()}
                >
                  Chat
                </Nav.Link>
                {/* <Button className="btnRgstr">Explore Booking</Button> */}
                <Dropdown className="ProfileDrp">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="fs-4"
                  >
                    <CgProfile />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href={user?.role.includes('admin') ? '/' : '/signup'}
                      onClick={() =>
                        user?.role.includes('admin') && setUser('admin')
                      }
                    >
                      {user?.role.includes('admin')
                        ? 'My Banquet'
                        : '+ Register Your Banquets'}
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/"
                      onClick={() =>
                        user?.role.includes('customer')
                          ? setUser('customer')
                          : setUser('')
                      }
                    >
                      Explore Banquets
                    </Dropdown.Item>
                    {(user?.role.includes('admin') ||
                      user?.role.includes('customer')) && (
                      <Dropdown.Item
                        href="/"
                        className="d-flex align-items-center gap-3"
                        onClick={() => logout()}
                      >
                        <CiLogout />
                        Logout
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    </>
  );
};

export default SecondaryHeader;
