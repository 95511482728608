import { IBanquetRoot } from '../interface/banquetDetail.interface';
import { IBanquetForm, IBookingForm } from '../interface/form.interface';
import { api } from '../utils/axios';
import { authApi } from './auth.service';

const getAdminBanquets = async () => {
  const response = await api.get<IBanquetRoot>('/banquet/getBanquets');
  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};

const editBanquet = async (
  banquetId: string,
  banquetData: IBanquetForm,
  files: File[],
  pervImages: string[]
) => {
  const formData = new FormData();
  const dummyPrevImages = pervImages.filter((item) => item.startsWith('https'));
  console.log('prevImagesLength', pervImages.length);

  files.map((item) => formData.append('images', item));
  Object.keys(banquetData).forEach((key) => {
    //@ts-ignore
    formData.append(key, banquetData[key]);
  });
  if (dummyPrevImages.length) {
    pervImages.forEach(
      (item) => item.startsWith('https') && formData.append('PrevImages', item)
    );
  }
  const response = await api.post(
    `/banquet/editBanquet/${banquetId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};

const createBanquet = async (banquetData: IBanquetForm, files: File[]) => {
  const formData = new FormData();
  files.map((item) => formData.append('images', item));
  Object.keys(banquetData).forEach((key) => {
    //@ts-ignore
    formData.append(key, banquetData[key]);
  });
  console.log('App', formData.get('images'));
  const response = await api.post(`/banquet/createBanquet`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};

const getCustomerBanquets = async (value: string) => {
  const response = await api.get<IBanquetRoot>(
    `/guest/banquet/getBanquetsByName?name=${value}`
  );
  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};
const getBanquetByCity = async (value: string) => {
  const response = await authApi.get<IBanquetRoot>(
    `/guest/banquet/getBanquetsByCity/${value}`
  );
  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};
const getBanquetsByArea = async (
  city: string,
  lat: number,
  lng: number,
  range: number
) => {
  const response = await api.get<IBanquetRoot>(
    `guest/banquet/getBanquetsNearMe/${city}/${lat}/${lng}/${range}`
  );
  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};

const banquetBooking = async (data: IBookingForm, hallId: string) => {
  const response = await api.post(`/bookings/createBooking/${hallId}`, {
    //   firstName: 'Adil',
    //   lastName: 'Amir 23444 22',
    //   email: 'adil@example.com',
    //   eventDate: '01/21/2024',
    //   eventSlot: 'lunch',
    //   decor: {
    //     status: true,
    //     package: 'Gold',
    //   },
    //   numberOfGuests: 500,
    //   requestedPrice: 2000,
    //   message: 'This is a test booking',
    // }
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    eventDate: data.eventDate,
    eventSlot: data.eventSlot,
    decor: {
      status: data.decor ? true : false,
      package: data.decor ? data.decor : '',
    },
    numberOfGuests: parseInt(data.numberOfGuests as unknown as string, 10),
    requestedPrice: parseInt(data.requestedPrice as unknown as string, 10),
    message: data.message,
  });
  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};

export const banquetSvc = {
  getAdminBanquets,
  getCustomerBanquets,
  editBanquet,
  createBanquet,
  getBanquetByCity,
  getBanquetsByArea,
  banquetBooking,
};
