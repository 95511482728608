import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

const PrimaryHeader = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth?.User);
  return (
    <>
      {!(user?.role.includes('admin') || user?.role.includes('customer')) && (
        <header className="PrimaryHeader px-4 py-2">
          <div className="d-flex align-items-center justify-content-end">
            {/* <img className="wlogo" src="/images/weblogo.svg" alt="Web logo" /> */}
            <div className="d-flex align-items-center gap-3">
              <Button className="btnRgstr" onClick={() => navigate('/signup')}>
                Register
              </Button>
              <Button className="btnLogin" onClick={() => navigate('/login')}>
                Login
              </Button>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default PrimaryHeader;
