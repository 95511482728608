import { ISaloon } from '../interface/saloonDetails.interface';
import { api } from '../utils/axios';

const getSaloons = async (
    city?: string,
    lat?: number,
    lng?: number,
    range?: number,
    name?:string
  ) => {
    console.log('lat,lat',lat,lng)
    const response = await api.get<ISaloon>(
      `/salon/getSalons?name=${name}&city=${city}&lat=${lat}&lng=${lng}&range=${range}`
    );
    if (response.ok) {
      console.log('Response: ', response.data);
      return response.data;
    } else {
      return null;
    }
  };
  
export const salonSvc = {
  getSaloons,
};
