import { useEffect, useState } from 'react';
import './index.css';
import BanquetModal from '../../components/BanquetModal/BanquetModal';
import BanquetDetailModal from '../../components/BanquetDetailModal/BanquetDetailModal';
import { logout } from '../../service/auth.service';
import { banquetSvc } from '../../service/banquet.service';
import { IBanquet } from '../../interface/banquetDetail.interface';
import { Link, useNavigate } from 'react-router-dom';
import { CiLogout } from 'react-icons/ci';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { BiSolidError, BiSolidExit } from 'react-icons/bi';
import Carousel from 'react-bootstrap/Carousel';
import SecondaryHeader from '../../components/Headers/SecondaryHeader';
import PrimaryHeader from '../../components/Headers/PrimaryHeader';

const BanquetScreen = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [banquets, setBanquets] = useState<IBanquet[]>([]);
  const [banquetData, setBanquetData] = useState<IBanquet | null>();
  const [selectedItem, setSelectedItem] = useState<{ name: string }>();

  const user = useSelector((state: RootState) => state.auth?.User);

  const navigation = useNavigate();
  const openModal = () => {
    setShowModal(true);
  };
  const banquetHandleClick = (name: string) => {
    setSelectedItem({ name: name });
  };
  const closeModal = () => {
    setBanquetData(null);
    setShowModal(false);
    getData();
  };

  const getData = async () => {
    const resp = await banquetSvc.getAdminBanquets();
    if (resp) {
      setBanquets(resp?.banquets);
    }
  };

  const editBanquet = (banquetData: IBanquet) => {
    setBanquetData(banquetData);
    navigation(`/NewBanquetBooking`, { state: { banquetData: banquetData } });
  };
  const onHallsShow = (id: string) => {
    navigation(`/halls`, { state: { banquetId: id } });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <PrimaryHeader />
      <SecondaryHeader />
      <section className="banquet-register-button">
        <div className="banquet-register">
          <Link
            to="/NewBanquetBooking"
            className="booking-button text-decoration-none"
          >
            + Register A New Banquet
          </Link>
        </div>
      </section>
      <section className="data-list">
        {banquets?.length === 0 && (
          <div className="nodata-error">
            <BiSolidError />
            <h2>No Banquet Found</h2>
          </div>
        )}

        <div className="container">
          <div className="container">
            <div className="row row-cols-1 row-cols-md-2 g-4">
              {banquets?.map((banquet, index) => (
                <div key={index} className="col">
                  <div className="card h-100">
                    <img
                      src={
                        banquet.Images?.length
                          ? banquet?.Images[0]
                          : 'https://mobile-cuisine.com/wp-content/uploads/2022/03/wedding-table-gf805441aa_1280.jpg'
                      }
                      className="card-img-top"
                      alt={`Banquet ${index + 1}`}
                      style={{ objectFit: 'cover', height: '200px' }} // Set desired height for the image
                    />
                    <div className="card-body">
                      <h3 className="card-title">{banquet.Name}</h3>
                      <p className="card-text mb-2">
                        <strong>Parking Capacity:</strong>
                        {banquet.ParkingCapacity}
                      </p>
                      <p className="card-text mb-2">
                        <strong>Number of Halls:</strong>{' '}
                        {banquet.NumberOfHalls}
                      </p>
                      <p className="card-text mb-2">
                        <strong>Price Range:</strong> {banquet.MinPrice} -
                        {banquet.MaxPrice} (Rs.)
                      </p>
                      <p className="card-text mb-2">
                        <strong>Area:</strong>&nbsp;
                        {banquet.Location.Area}
                      </p>
                      <p className="card-text mb-2">
                        <strong>City:</strong>
                        {banquet.Location.City}
                      </p>
                      <div className="d-flex justify-content-center mb-3">
                        <button
                          className="btn btn-secondary me-2 w-25"
                          onClick={() => editBanquet(banquet)}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-secondary me-2 w-25"
                          onClick={() => onHallsShow(banquet.ID)}
                        >
                          Venues
                        </button>
                      </div>
                      <div className="text-center mt-2 ">
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            navigation(`/bookings`, {
                              state: { banquetId: banquet.ID },
                            })
                          }
                        >
                          Booking Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {showModal && (
        <div className="modal-backdrop-default">
          <BanquetModal closeModal={closeModal} banquetData={banquetData} />
        </div>
      )}
      {selectedItem?.name && (
        <div className="modal-backdrop-default">
          <BanquetDetailModal
            title={selectedItem.name}
            closeBanquetModal={() => setSelectedItem({ name: '' })}
          />
        </div>
      )}
    </>
  );
};

export default BanquetScreen;
