import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PrimaryHeader from '../../components/Headers/PrimaryHeader';
import SecondaryHeader from '../../components/Headers/SecondaryHeader';
import { ENV } from '../../env/enviornmentConstants';
import { mapsSvc } from '../../service/maps.service';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { banquetSvc } from '../../service/banquet.service';
import { IBanquetForm } from '../../interface/form.interface';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';

const NewBanquetBooking = () => {
  const locationState = useLocation();
  const { banquetData } = locationState.state || {};
  const navigate = useNavigate();

  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 7.2905715,
    lng: 80.6337262,
  });
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<IBanquetForm>();
  const onSubmit = async (data: IBanquetForm) => {
    if (selectedImages?.length < 1) {
      setImageError(true);
      return;
    }
    console.log(data, 'Lat: ', location.lat, 'Lng: ', location.lng);

    if (banquetData) {
      await banquetSvc.editBanquet(
        banquetData.ID,
        data,
        uploadedImages,
        selectedImages
      );
    } else {
      await banquetSvc.createBanquet(data, uploadedImages);
    }
    navigate('/');
    reset();
  };

  useEffect(() => {
    if (banquetData) {
      setValue('area', banquetData.Location.Area);
      setValue('city', banquetData.Location.City);
      setValue('lat', parseFloat(banquetData.Location.Lat));
      setValue('long', parseFloat(banquetData.Location.Long));
      setLocation({
        lat: parseFloat(banquetData.Location.Lat),
        lng: parseFloat(banquetData.Location.Long),
      });
      setSelectedImages(banquetData?.Images);
    }
  }, []);

  const handleValue = async (place: any) => {
    if (!place) return;
    console.log('Data', place.label);
    await geocodeByAddress(place.label)
      .then((results) => getLatLng(results[0]))
      .then(async ({ lat, lng }: { lat: number; lng: number }) => {
        console.log('Successfully got latitude and longitude', { lat, lng });
        setLocation({ lat: lat, lng: lng });
        setValue('lat', lat);
        setValue('long', lng);
        const resp = await mapsSvc.getCityArea(lat, lng);
        console.log('Resp for City: ', resp);
        if (resp?.city && resp?.area) {
          console.log('Resp: ', resp.area, resp.city);
          setValue('city', resp.city);
          setValue('area', resp.area);

          clearErrors(['area', 'city']);
        }
      });
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    let allImages: File[] = [];
    let viewImages: string[] = [];
    if (event.target.files) {
      const files = Array.from(event.target.files);

      if (selectedImages?.length + files.length > ENV.MAX_FILES_ALLOWED) {
        alert(`You can select a maximum of ${ENV.MAX_FILES_ALLOWED} files.`);
        event.preventDefault();
        event.target.files = null;
      } else {
        const imagesArray = files.map((file) => URL.createObjectURL(file));

        viewImages = Array.isArray(selectedImages)
          ? [...selectedImages, ...imagesArray]
          : imagesArray;
        allImages = [...uploadedImages, ...files];
        console.log('All Images: ', allImages);
        console.log('View Images: ', viewImages);
        setUploadedImages(allImages);
        setSelectedImages(viewImages);
        setImageError(false);
      }
    }
  };

  const removeImage = (index: number) => {
    const updatedImages = [...selectedImages];
    const updatedFiles = [...uploadedImages];
    console.log('Before selected Images', updatedImages);
    console.log('Before Uploaded Files', updatedFiles);
    console.log('Index Number', index);
    const filesIndex = index - 1;
    if (selectedImages[index].startsWith('https')) {
      updatedImages.splice(index, 1);
    } else if (selectedImages.length === updatedFiles.length) {
      updatedImages.splice(index, 1);
      updatedFiles.splice(index, 1);
    } else {
      updatedImages.splice(index, 1);
      updatedFiles.splice(filesIndex, 1);
    }
    console.log('After selected Images', updatedImages);
    console.log('After Uploaded Files', updatedFiles);
    setSelectedImages(updatedImages);
    setUploadedImages(updatedFiles);
  };
  return (
    <>
      <header className="customer-screen-header bg-body-tertiary">
        <button className="booking-button " onClick={() => navigate('/')}>
          Go Back
        </button>
      </header>
      <div className="NewBanquetBooking">
        <h3>{banquetData ? 'Edit' : 'New'} Banquet Details</h3>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="validationCustom01" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Enter Name"
              defaultValue={banquetData?.Name}
              {...register('name', {
                required: 'Banquet Name is Required',
                minLength: {
                  value: 5,
                  message: 'Banquet Name is Required',
                },
              })}
            />
            {errors.name && (
              <span className="loginErrorMessage">{errors.name.message}</span>
            )}
          </Form.Group>
          <Form.Group controlId="validationCustom01" className="mb-3">
            <Form.Control
              required
              type="email"
              placeholder="Enter Email"
              defaultValue={banquetData?.Contact.Email}
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid email address',
                },
              })}
            />
            {errors.email && (
              <span className="loginErrorMessage">{errors.email.message}</span>
            )}
          </Form.Group>
          <Form.Group controlId="validationCustom01" className="mb-3">
            <GooglePlacesAutocomplete
              selectProps={{
                onChange: handleValue,
              }}
              autocompletionRequest={{
                bounds: [
                  { lat: 23.6345, lng: 60.872972 },
                  { lat: 37.084107, lng: 77.831879 },
                ],
                componentRestrictions: {
                  country: 'PK',
                },
              }}
              {...register('city', {
                required: 'City is required',
                minLength: {
                  value: 4,
                  message: 'City is Required',
                },
              })}
              {...register('area', {
                required: 'Area is required',
                minLength: {
                  value: 4,
                  message: 'Area is Required',
                },
              })}
            />
            <p>
              <label className="city-area-text pt-3">City: </label>{' '}
              {getValues('city') || banquetData?.Location.City}
            </p>
            {errors.city && (
              <span className="defaultError">{errors.city.message}</span>
            )}
            <p>
              <label className="city-area-text">Area: </label>{' '}
              {getValues('area') || banquetData?.Location.Area}
            </p>
            {errors.area && (
              <span className="loginErrorMessage">{errors.area.message}</span>
            )}
          </Form.Group>
          <Form.Group controlId="validationCustom01" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Enter Contact Number"
              defaultValue={banquetData?.Contact.ContactNumber}
              {...register('contactNumber', {
                required: 'Contant No is Required',
                minLength: {
                  value: 11,
                  message: 'Contact No is Required',
                },
              })}
            />
            {errors.contactNumber && (
              <span className="loginErrorMessage">
                {errors.contactNumber.message}
              </span>
            )}
          </Form.Group>
          <Form.Group controlId="validationCustom01" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Enter Total Halls"
              defaultValue={banquetData?.NumberOfHalls}
              {...register('numberOfHalls', {
                required: 'Number of halls is required',
                min: {
                  value: 1,
                  message:
                    'Number of halls should be greater than or equal to 1',
                },
              })}
            />
            {errors.numberOfHalls && (
              <span className="loginErrorMessage">
                {errors.numberOfHalls.message}
              </span>
            )}
          </Form.Group>
          <Form.Group controlId="validationCustom01" className="mb-3">
            <Form.Control
              required
              type="number"
              placeholder="Enter Minimum Price"
              defaultValue={banquetData?.MinPrice}
              {...register('minPrice', {
                required: 'Minimum Price is required',
                min: {
                  value: 1,
                  message: 'Minimum Price is required',
                },
              })}
            />
            {errors.minPrice && (
              <span className="loginErrorMessage">
                {errors.minPrice.message}
              </span>
            )}
          </Form.Group>
          <Form.Group controlId="validationCustom01" className="mb-3">
            <Form.Control
              required
              type="number"
              placeholder="Enter Maximum Price"
              defaultValue={banquetData?.MaxPrice}
              {...register('maxPrice', {
                required: 'Maximum Price is required',
                min: {
                  value: 1,
                  message: 'Maximum Price is required',
                },
                validate: (value) => {
                  //@ts-ignore
                  const minPrice = parseInt(getValues('minPrice'), 10);
                  if (isNaN(minPrice) || value < minPrice) {
                    return 'Maximum Price must be greater than Minimum Price';
                  }
                },
              })}
            />
            {errors.maxPrice && (
              <span className="loginErrorMessage">
                {errors.maxPrice.message}
              </span>
            )}
          </Form.Group>
          <Form.Group controlId="validationCustom01" className="mb-3">
            <Form.Control
              required
              type="number"
              placeholder="Enter Parking Capacity"
              defaultValue={banquetData?.ParkingCapacity}
              {...register('parkingCapacity', {
                required: 'Parking Capacity is required',
                min: {
                  value: 1,
                  message: 'Parking Capacity is required',
                },
              })}
            />
            {errors.parkingCapacity && (
              <span className="loginErrorMessage">
                {errors.parkingCapacity.message}
              </span>
            )}
          </Form.Group>
          <div className="imageContainer">
            {selectedImages?.map((image, index) => (
              <div key={index}>
                <img alt={`image-${index}`} width={'150px'} src={image} />
                <br />
                <button
                  className="removeButton"
                  onClick={() => removeImage(index)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
          <label
            className={`${
              selectedImages?.length >= 6
                ? 'fileInputLabelDisabled'
                : 'fileInputLabel'
            }`}
            style={{ borderRadius: '12px' }}
          >
            <input
              type="file"
              name="myImage"
              onChange={handleImageChange}
              multiple
              className="fileInput"
              accept="image/*"
              disabled={selectedImages?.length >= 6}
            />
            Upload Image
          </label>
          {imageError && (
            <span className="loginErrorMessage">Image is Required</span>
          )}
          <Button className="buttonSubmit" variant="primary" type="submit">
            {' '}
            {banquetData ? 'Edit' : 'Create'} Banquet
          </Button>
        </Form>
      </div>
    </>
  );
};

export default NewBanquetBooking;
