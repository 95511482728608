import { useState } from 'react';
import SignInForm from '../../components/SignInForm/SignInForm';
import './index.css';
import { Button, Modal } from 'react-bootstrap';
const SignUpScreen = () => {
  return (
    <div className="mainLoginContainer">
      <SignInForm />
    </div>
  );
};

export default SignUpScreen;
