import { create } from "apisauce";
import { API_BASE_URL } from "../env/enviornmentConstants";
import spinnerSvc from "../utils/spinner-service";
import {
  removeUser,
  setAuthUser,
  setIsSelected,
} from "../features/Auth Slice/authSlice";
import { store } from "../store/store";
import { ITokens } from "../interface/ITokens.interface";
import {
  ILoginForm,
  ILoginSocial,
  ISignUp,
  ISignUpForm,
} from "../interface/form.interface";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { fbLogOut } from "./firebaseAuthService.service";

export const authApi = create({
  baseURL: API_BASE_URL,
  headers: { Accept: "application/json" },
});

authApi.axiosInstance.interceptors.request.use(
  async (config) => {
    spinnerSvc.start();
    return config;
  },
  (err) => console.error(err)
);
authApi.axiosInstance.interceptors.response.use(
  (response) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
  }
);

export const authLogin = async (loginRequest: ILoginForm) => {
  const response: any = await authApi.post("/user/signIn", loginRequest);
  if (response?.data) {
    const tokenBase64 = response?.data?.access_token;
    const tokenData: any = await jwtDecode(tokenBase64);

    if (tokenData.role.includes("customer")) {
      console.log(tokenData.role);
      tokenData.isSelectedRole = "customer";
      localStorage.setItem("selectedRole", tokenData.isSelectedRole);
    } else if (tokenData.role.includes("admin")) {
      tokenData.isSelectedRole = "admin";
      localStorage.setItem("selectedRole", tokenData.isSelectedRole);
    }
    console.log("Token Data", tokenData);
    localStorage.setItem("user", JSON.stringify(tokenData));
    store.dispatch(setAuthUser(tokenData));
    storeTokens(response?.data);
    return response?.data;
  } else {
    if ((response.error = "invalid email or password")) {
      toast.error("Invalid Email or Password / User does not exist");
    }else{
      toast.error("Verify Email First");
    }
  }
  return null;
};

export const authLoginSocial = async (loginRequest: ILoginSocial) => {
  const response: any = await authApi.post(
    `/user/signIn?socialLogin=${true}`,
    loginRequest
  );
  if (response?.data) {
    const tokenBase64 = response?.data?.access_token;
    const tokenData: any = await jwtDecode(tokenBase64);
    console.log("Token Data", tokenData);
    localStorage.setItem("user", JSON.stringify(tokenData));
    store.dispatch(setAuthUser(tokenData));
    storeTokens(response?.data);
    return response?.data;
  } else {
    window.alert("Invalid Email or Password");
    return null;
  }
};

// var Body struct {
//   Name    string `json:"name" validate:"required"`
//   Email   string `json:"email" validate:"required"`
//   Contact string `json:"contact"`
//   Role    string `json:"role" validate:"required"`
// }
export const userRegister = async (payload: ISignUp) => {
  try {
    console.log(payload);
    const response = await authApi.post("/user/signUp", {
      name: payload.name,
      email: payload.email,
      contact: payload.contact,
      role: payload.role,
    });
    console.log("Response", response);
    if (response.ok) {
      return response?.data;
    }
    return false;
  } catch (error) {
    console.log("Error", error);
    toast.error('There is an error. Please Try Again')
    return { err: error };
  }
};

export const refreshToken = async () => {
  const rtToken = localStorage.getItem("rtToken");
  try {
    console.log("call new token", rtToken);
    const { data, ok }: any = await authApi.get(
      "/user/refreshTokens",
      undefined,
      {
        headers: { Authorization: `Bearer ${rtToken}` },
      }
    );
    console.log("refresh Token", data);
    if (ok) {
      storeTokens(data);
      return data?.access_token;
    }

    return null;
  } catch (error) {
    return null;
  }
};
// user/verifyEmail/:email

export const verifyEmail = async (email: string) => {
  try {
    const { data, ok }: any = await authApi.post(`/user/verifyEmail/${email}`);
    console.log("refresh Token", data);
    if (ok) {
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};
export const storeTokens = (tokens: ITokens) => {
  const { refresh_token, access_token } = tokens;
  console.log("tokens", tokens);
  localStorage.setItem("acToken", access_token);
  localStorage.setItem("rtToken", refresh_token);
};

export const setUser = (user: string) => {
  localStorage.setItem("selectedRole", user);
  store.dispatch(setIsSelected(user));
};
export const logout = () => {
  fbLogOut();
  store.dispatch(removeUser());
};
