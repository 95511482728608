import React, { useState } from "react";
import { hallSvc } from "../../service/hall.service";
import { IDecorObject } from "../../interface/booking.interface";
interface IProps {
  closeModal: Function;
  venueId: string;
}

interface CheckboxState {
  checked: boolean;
  value: string;
}

const DecorModal = (props: IProps) => {
  const initialCheckboxValues: { [key: string]: CheckboxState } = {
    standard: { checked: false, value: "" },
    gold: { checked: false, value: "" },
    premium: { checked: false, value: "" },
  };

  const [checkboxValues, setCheckboxValues] = useState(initialCheckboxValues);

  const handleCheckboxChange = (id: string) => {
    setCheckboxValues((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        checked: !prevState[id].checked,
        value: !prevState[id].checked ? prevState[id].value : "",
      },
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    let { value } = event.target;
    value = value.replace(/^0+/, "");
    // Ensure it's a non-negative number
    value = value.replace(/[^0-9]/g, "");
    setCheckboxValues((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value: value,
      },
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("Checkbox Values:", checkboxValues);
    const checkedItems = Object.entries(checkboxValues)
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => {
        return {
          type: key,
          price: parseInt(value.value), // Use a default price if value is empty or invalid
        };
      });
    const response = hallSvc.addDecorInMenu(
      props.venueId,
      checkedItems as IDecorObject[]
    );
    if (response !== null) {
      props.closeModal();
    }
  };

  return (
    <div className="banquetModal">
      <div className="modal-header">
        <h5 className="modal-title">Decor Packages</h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => props.closeModal()}
        ></button>
      </div>

      <form onSubmit={handleSubmit} className="mt-3">
        {Object.entries(checkboxValues).map(([id, { checked, value }]) => (
          <div className="form-check form-check-inline mb-3" key={id}>
            <input
              className="form-check-input inputmaxWidth"
              type="checkbox"
              id={id}
              checked={checked}
              onChange={() => handleCheckboxChange(id)}
            />
            <label
              className="form-check-label text-capitalize ms-2"
              htmlFor={id}
            >
              {id}
            </label>
            <input
              type="number"
              value={value}
              onChange={(e) => handleInputChange(e, id)}
              placeholder="Enter Price"
              disabled={!checked}
              className="form-control ms-2"
            />
          </div>
        ))}
        <div className="row justify-content-center">
          <div className="col-auto">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>

      <button onClick={() => props.closeModal()} className="btn btn-secondary">
        Skip Decor
      </button>
    </div>
  );
};

export default DecorModal;
