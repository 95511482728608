import { useForm } from 'react-hook-form';
import { ILoginForm } from '../../interface/form.interface';
import './index.css';
import { Button } from 'react-bootstrap';
import { FcGoogle } from 'react-icons/fc';
import { LiaFacebookF } from 'react-icons/lia';
import { useEffect, useState } from 'react';
import SignInForm from '../../components/SignInForm/SignInForm';
import { authLogin, verifyEmail } from '../../service/auth.service';
import {
  signInWithPopup,
  FacebookAuthProvider,
  sendPasswordResetEmail,
  applyActionCode
} from 'firebase/auth';
import { auth, provider, facebookProvider } from '../../service/firebaseConfig';
import { signInWithFb } from '../../service/firebaseAuthService.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const LoginScreen = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const email: string = new URLSearchParams(window.location.search).get(
  //     "email"
  //   ) as string;
  //   // if (email !== "") {
  //   //   verifyNewEmail(email);
  //   // }
  // }, []);

  // const verifyNewEmail = async (email: string) => {
  //   try {
  //     await verifyEmail(email);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const location = useLocation()
  useEffect(()=>{

    const queryParams = new URLSearchParams(location.search);
    const oobcode: string = queryParams.get('actionCode') || '';
    if(!queryParams){
      return
    }else{
      handleResetPassword(oobcode)
    }
  },[])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ILoginForm>();

  const handleResetPassword=async(actionCode:string)=> {
    try {
      await applyActionCode(auth, actionCode);
      // Display UI and handle password reset
      // For example, show a password reset form
    } catch (error) {
      // Handle error
      console.error('Error verifying password reset code:', error);
    }
  }
  
  const onSubmit = async (data: ILoginForm) => {
    console.log(data);
    try {
      const res = await signInWithFb(data);
      console.log('res', res);
      const login = await authLogin({
        email: res.user.email,
        isVerified: res.user.emailVerified,
      });
      console.log('login', login);
      if (login !== null) {
        navigate('/');
      }
    } catch (error) {
      toast.error('Please Verify Your Email');
    }

    reset();
  };

  function googleSignIn() {
    signInWithPopup(auth, provider)
      .then(async (data) => {
        const res = await authLogin({
          email: data.user.email as string,
          isVerified: data.user.emailVerified,
        });
        if (res !== null) {
          navigate('/');
        }
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  }

  function facebookSignIn() {
    signInWithPopup(auth, facebookProvider)
      .then(async (data) => {
        const res = await authLogin({
          email: data.user.email as string,
          isVerified: true,
        });
        if (res !== null) {
          navigate('/');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  // async function authWithFacebook() {
  //   try {
  //     const provider = new FacebookAuthProvider();
  //     const result: any = await signInWithPopup(auth, provider);
  //     const credential: any = FacebookAuthProvider.credentialFromResult(result);
  //     const token = credential.accessToken;
  //     console.log("Result",result)
  //     // await authLogin({email:data.user.email, isVerified:data.user.emailVerified});

  //     // await authLoginSocial({ token: token });
  //     console.log('Access token: ', token);
  //     return { isLoggedIn: true, token };
  //   } catch (error) {
  //     console.log('Error: ', error);
  //     return { isLoggedIn: false, error };
  //   }
  // }
  const actionCodeSettings = {
    url: 'http://localhost:3000/reset',
    handleCodeInApp: true,
  };

  const forgotPassword = (email: string) => {
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        toast.info('Password reset email sent!');
      })
      .catch((error) => {
        toast.error(`${error.code} ${error.message}`);
      });
  };

  return (
    <div>
      <div className="mainLoginContainer">
        <Button
          className="btnBeforeLogin"
          onClick={() => forgotPassword('theadilamir@gmail.com')}
        >
          <LiaFacebookF />
        </Button>

        <form onSubmit={handleSubmit(onSubmit)} className="loginForm">
          <h2>Login</h2>
          <div className="form-group">
            <label className="labelText">Email</label>
            <input
              placeholder="Enter Email"
              className="inputWidth"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid email address',
                },
              })}
            />
            {errors.email && (
              <span className="loginErrorMessage">{errors.email.message}</span>
            )}
          </div>
          <div className="form-group">
            <label className="labelText">Password</label>
            <input
              placeholder="Enter Password"
              className="inputWidth"
              type="password"
              {...register('password', {
                required: 'Please Enter Your Password',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters',
                },
              })}
            />
            {errors.password && (
              <span className="loginErrorMessage">
                {errors.password.message}
              </span>
            )}
          </div>
          <button type="submit" className="buttonSubmit">
            Login
          </button>
          <Button className="google" onClick={googleSignIn}>
            <FcGoogle />
          </Button>
          <Button className="btnBeforeLogin" onClick={facebookSignIn}>
            <LiaFacebookF />
          </Button>
          <div className="signUpContainer">
            <span className="signUpLink" onClick={() => navigate('/signup')}>
              Click Here To Register Yourself
            </span>
          </div>
        </form>
        {/* <button onClick={googleSignIn}>Google</button> */}
      </div>
    </div>
  );
};

export default LoginScreen;
