import {
  IBooking,
  IMainBooking,
  IMainVenueBooking,
  IVenueBooking,
} from '../interface/booking.interface';
import { api } from '../utils/axios';

const getuserBookings = async () => {
  const response = await api.get<IMainBooking>(`/bookings/getBookingsOfUser`);

  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};

const getBookingsById = async (Id: string) => {
  const response = await api.get<IMainVenueBooking>(
    `/bookings/GetBookingByBanquet/${Id}`
  );

  if (response.ok) {
    console.log('Response For Booking: ', response.data);
    return response.data;
  } else {
    return null;
  }
};

const responseBooking = async (id: string, offer: string) => {
  const response = await api.patch(`/bookings/respondBooking/${id}/${offer}`);

  if (response.ok) {
    console.log('Response For Booking: ', response.data);
    return response.data;
  } else {
    window.alert('A booking already exists for the specified date and time');
    return null;
  }
};

export const bookingSvc = {
  getuserBookings,
  getBookingsById,
  responseBooking,
};
