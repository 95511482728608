import { IStudio } from '../interface/studioDetail.interface';
import { api } from '../utils/axios';

const getStudios = async (
    city?: string,
    lat?: number,
    lng?: number,
    range?: number,
    name?:string
  ) => {
    console.log('lat,lat',lat,lng)
    const response = await api.get<IStudio>(
      `/studio/getStudios?name=${name}&city=${city}&lat=${lat}&lng=${lng}&range=${range}`
    );
    if (response.ok) {
      console.log('Response: ', response.data);
      return response.data;
    } else {
      return null;
    }
  };
  
export const studioSvc = {
  getStudios,
};