import { useForm } from 'react-hook-form';
import './index.css';
import { IBanquetForm } from '../../interface/form.interface';
import { ChangeEvent, useEffect, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { mapsSvc } from '../../service/maps.service';
import { ENV } from '../../env/enviornmentConstants';
import { IBanquet } from '../../interface/banquetDetail.interface';
import { banquetSvc } from '../../service/banquet.service';

interface IProps {
  closeModal: Function;
  banquetData?: IBanquet | null;
}

const BanquetModal = (props: IProps) => {
  const { banquetData } = props;
  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 7.2905715,
    lng: 80.6337262,
  });
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<IBanquetForm>();
  const onSubmit = async (data: IBanquetForm) => {
    if (selectedImages?.length < 1) {
      setImageError(true);
      return;
    }
    console.log(data, 'Lat: ', location.lat, 'Lng: ', location.lng);

    if (banquetData) {
      await banquetSvc.editBanquet(
        banquetData.ID,
        data,
        uploadedImages,
        selectedImages
      );
    } else {
      await banquetSvc.createBanquet(data, uploadedImages);
    }
    props.closeModal();
    reset();
  };

  useEffect(() => {
    if (banquetData) {
      setValue('area', banquetData.Location.Area);
      setValue('city', banquetData.Location.City);
      setValue('lat', parseFloat(banquetData.Location.Lat));
      setValue('long', parseFloat(banquetData.Location.Long));
      setLocation({
        lat: parseFloat(banquetData.Location.Lat),
        lng: parseFloat(banquetData.Location.Long),
      });
      setSelectedImages(banquetData?.Images);
    }
  }, []);

  const handleValue = async (place: any) => {
    if (!place) return;
    console.log('Data', place.label);
    await geocodeByAddress(place.label)
      .then((results) => getLatLng(results[0]))
      .then(async ({ lat, lng }: { lat: number; lng: number }) => {
        console.log('Successfully got latitude and longitude', { lat, lng });
        setLocation({ lat: lat, lng: lng });
        setValue('lat', lat);
        setValue('long', lng);
        const resp = await mapsSvc.getCityArea(lat, lng);
        if (resp?.city && resp?.area) {
          console.log('Resp: ', resp.area, resp.city);
          setValue('city', resp.city);
          setValue('area', resp.area);

          clearErrors(['area', 'city']);
        }
      });
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    let allImages: File[] = [];
    let viewImages: string[] = [];
    if (event.target.files) {
      const files = Array.from(event.target.files);

      if (selectedImages?.length + files.length > ENV.MAX_FILES_ALLOWED) {
        alert(`You can select a maximum of ${ENV.MAX_FILES_ALLOWED} files.`);
        event.preventDefault();
        event.target.files = null;
      } else {
        const imagesArray = files.map((file) => URL.createObjectURL(file));

        viewImages = Array.isArray(selectedImages)
          ? [...selectedImages, ...imagesArray]
          : imagesArray;
        allImages = [...uploadedImages, ...files];
        console.log('All Images: ', allImages);
        console.log('View Images: ', viewImages);
        setUploadedImages(allImages);
        setSelectedImages(viewImages);
        setImageError(false);
      }
    }
  };

  const closeModal = () => {
    props.closeModal();
    reset();
  };
  const removeImage = (index: number) => {
    const updatedImages = [...selectedImages];
    const updatedFiles = [...uploadedImages];
    console.log('Before selected Images', updatedImages);
    console.log('Before Uploaded Files', updatedFiles);
    console.log('Index Number', index);
    const filesIndex = index - 1;
    if (selectedImages[index].startsWith('https')) {
      updatedImages.splice(index, 1);
    } else if (selectedImages.length === updatedFiles.length) {
      updatedImages.splice(index, 1);
      updatedFiles.splice(index, 1);
    } else {
      updatedImages.splice(index, 1);

      updatedFiles.splice(filesIndex, 1);
    }
    console.log('After selected Images', updatedImages);
    console.log('After Uploaded Files', updatedFiles);
    setSelectedImages(updatedImages);
    setUploadedImages(updatedFiles);
  };
  return (
    <div className="banquetModal">
      <div className="modal-header">
        <h5 className="modal-title">
          {banquetData ? 'Edit' : 'New'} Banquet Details
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => closeModal()}
        ></button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="banquetForm">
        {/* <h2>{banquetData ? 'Edit' : 'New'} Banquet Details</h2> */}
        <div className="form-group inputmaxWidth">
          <label className="labelText">Banquet Name</label>
          <input
            placeholder="Enter Name"
            className="inputWidth"
            autoComplete="off"
            defaultValue={banquetData?.Name}
            {...register('name', {
              required: 'Banquet Name is Required',
              minLength: {
                value: 5,
                message: 'Banquet Name is Required',
              },
            })}
          />
          {errors.name && (
            <span className="loginErrorMessage">{errors.name.message}</span>
          )}
        </div>
        <div className="form-group inputmaxWidth">
          <label className="labelText">Email</label>
          <input
            placeholder="Enter Email"
            className="inputWidth"
            autoComplete="off"
            defaultValue={banquetData?.Contact.Email}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && (
            <span className="loginErrorMessage">{errors.email.message}</span>
          )}
        </div>
        <div className="form-group inputmaxWidth">
          <label className="labelText">Search Your Area</label>
          <GooglePlacesAutocomplete
            selectProps={{
              onChange: handleValue,
            }}
            autocompletionRequest={{
              bounds: [
                { lat: 23.6345, lng: 60.872972 },
                { lat: 37.084107, lng: 77.831879 },
              ],
              componentRestrictions: {
                country: 'PK',
              },
            }}
            {...register('city', {
              required: 'City is required',
              minLength: {
                value: 4,
                message: 'City is Required',
              },
            })}
            {...register('area', {
              required: 'Area is required',
              minLength: {
                value: 4,
                message: 'Area is Required',
              },
            })}
          />
          <p>
            <label className="city-area-text pt-3">City: </label>{' '}
            {getValues('city') || banquetData?.Location.City}
          </p>
          {errors.city && (
            <span className="defaultError">{errors.city.message}</span>
          )}
          <p>
            <label className="city-area-text">Area: </label>{' '}
            {getValues('area') || banquetData?.Location.Area}
          </p>
          {errors.area && (
            <span className="loginErrorMessage">{errors.area.message}</span>
          )}
        </div>
        <div className="form-group inputmaxWidth">
          <label className="labelText">Contact Number</label>
          <input
            placeholder="Enter Contact Number"
            type="tel"
            className="inputWidth"
            autoComplete="off"
            defaultValue={banquetData?.Contact.ContactNumber}
            {...register('contactNumber', {
              required: 'Contant No is Required',
              minLength: {
                value: 11,
                message: 'Contact No is Required',
              },
            })}
          />
          {errors.contactNumber && (
            <span className="loginErrorMessage">
              {errors.contactNumber.message}
            </span>
          )}
        </div>
        <div className="form-group inputmaxWidth">
          <label className="labelText">Total Halls</label>
          <input
            placeholder="Enter Number of Halls"
            type="number"
            className="inputWidth"
            defaultValue={banquetData?.NumberOfHalls}
            {...register('numberOfHalls', {
              required: 'Number of halls is required',
              min: {
                value: 1,
                message: 'Number of halls should be greater than or equal to 1',
              },
            })}
          />
          {errors.numberOfHalls && (
            <span className="loginErrorMessage">
              {errors.numberOfHalls.message}
            </span>
          )}
        </div>
        <div className="form-group inputmaxWidth">
          <label className="labelText">Minimum Price</label>
          <input
            placeholder="Enter Price"
            type="number"
            className="inputWidth"
            defaultValue={banquetData?.MinPrice}
            {...register('minPrice', {
              required: 'Minimum Price is required',
              min: {
                value: 1,
                message: 'Minimum Price is required',
              },
            })}
          />
          {errors.minPrice && (
            <span className="loginErrorMessage">{errors.minPrice.message}</span>
          )}
        </div>
        <div className="form-group inputmaxWidth">
          <label className="labelText">Maximum Price</label>
          <input
            placeholder="Enter Price"
            type="number"
            className="inputWidth"
            defaultValue={banquetData?.MaxPrice}
            {...register('maxPrice', {
              required: 'Maximum Price is required',
              min: {
                value: 1,
                message: 'Maximum Price is required',
              },
              validate: (value) => {
                //@ts-ignore
                const minPrice = parseInt(getValues('minPrice'), 10);
                if (isNaN(minPrice) || value < minPrice) {
                  return 'Maximum Price must be greater than Minimum Price';
                }
              },
            })}
          />
          {errors.maxPrice && (
            <span className="loginErrorMessage">{errors.maxPrice.message}</span>
          )}
        </div>
        <div className="form-group inputmaxWidth">
          <label className="labelText">Parking Capacity</label>
          <input
            placeholder="Enter Parking Capacity"
            type="number"
            className="inputWidth"
            defaultValue={banquetData?.ParkingCapacity}
            {...register('parkingCapacity', {
              required: 'Parking Capacity is required',
              min: {
                value: 1,
                message: 'Parking Capacity is required',
              },
            })}
          />
          {errors.parkingCapacity && (
            <span className="loginErrorMessage">
              {errors.parkingCapacity.message}
            </span>
          )}
        </div>

        <div>
          <div className="imageContainer">
            {selectedImages?.map((image, index) => (
              <div key={index}>
                <img alt={`image-${index}`} width={'150px'} src={image} />
                <br />
                <button
                  className="removeButton"
                  onClick={() => removeImage(index)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
          <br />
          <div className="form-group inputmaxWidth">
            <label
              className={`${
                selectedImages?.length >= 6
                  ? 'fileInputLabelDisabled'
                  : 'fileInputLabel'
              }`}
            >
              <input
                type="file"
                name="myImage"
                onChange={handleImageChange}
                multiple
                className="fileInput"
                accept="image/*"
                disabled={selectedImages?.length >= 6}
              />
              Upload Image
            </label>
            {imageError && (
              <span className="loginErrorMessage">Image is Required</span>
            )}
          </div>
        </div>
        <button type="submit" className="buttonSubmit">
          {banquetData ? 'Edit' : 'Create'} Banquet
        </button>
      </form>

      <button onClick={() => closeModal()} className="close-modal-btn">
        Close Modal
      </button>
    </div>
  );
};

export default BanquetModal;
