// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {FacebookAuthProvider, getAuth, GoogleAuthProvider} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC9tEWBSPrJQAWk0hSE-aZQpliwVp6BxQ4",
  authDomain: "a360-banquets.firebaseapp.com",
  projectId: "a360-banquets",
  storageBucket: "a360-banquets.appspot.com",
  messagingSenderId: "490207810397",
  appId: "1:490207810397:web:02aa1d2d7e44b395938eb4"
};

// personal test app
// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyAcpdYMXa_BdhG2EULVQlATbpPa1ycAs_0",
//     authDomain: "test-ad67e.firebaseapp.com",
//     projectId: "test-ad67e",
//     storageBucket: "test-ad67e.appspot.com",
//     messagingSenderId: "305274839556",
//     appId: "1:305274839556:web:7a23bfd3dd54366f124b51"
//   };
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider()

export {auth, provider, facebookProvider}