interface IProps {
  title: string;
  closeBanquetModal: Function;
}
const BanquetDetailModal = (props: IProps) => {
  return (
    <div className="banquetModal">
      <h1>{props.title}</h1>
      <button
        onClick={() => props.closeBanquetModal()}
        className="close-modal-btn"
      >
        Close Modal
      </button>
    </div>
  );
};

export default BanquetDetailModal;
