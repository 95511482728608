import './index.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { TiDeleteOutline } from "react-icons/ti";
import { IoSend } from "react-icons/io5";
import { Admin } from '../../interface/banquetDetail.interface';
import { doc, getDoc, getFirestore, increment, onSnapshot, setDoc } from "firebase/firestore";
import {app} from '../../service/firebaseConfig'
import { addDataToArrayFirebase, updateDataToFirebase } from '../../service/firebaseService.service';
import { useEffect, useState } from 'react';
interface IProps {
    closeChat: Function;
    admins:Admin[]

}

const ChatModal = (props: IProps) => {
    const { closeChat, admins } = props;
    const user = useSelector((state:RootState) => state.auth.User?.id!);
    const [combinedId,setCombinedId] = useState<string>('')
    const [message,setMessage] = useState<string>('')
    const [messages,setMessages] = useState<any>()
    const db = getFirestore(app);

    const handleSendMessage = async () => {
        try {

            const res = await getDoc(doc(db, "messages", combinedId));
            if (!res.exists()) {
                //create a chat in chats collection
                await setDoc(doc(db, "messages", combinedId), { messages: [] });
            }        

          const messageTemp = message;
        //   setMessage("");
          console.log('messages',messages)    
          await addDataToArrayFirebase(combinedId, {
            message: messageTemp,
            sender: user,
            time: Date.now(),
          });
          console.log("Incrementing count");
        //   try {
        //     await updateDataToFirebase(
        //       combinedId,
        //       {
        //         lastMessageIsImage: false,
        //         lastMessage: messageTemp,
        //         time: Date.now(),
        //         lastSender: user,
        //         unreadCount: increment(1),
        //       },
        //       "chats",
        //       true
        //     );
        //   } catch (error) {
        //     console.log("Error while fetching incrementing count", error);
        //   }
        } catch (error) {
          console.error("Error while sending message: ", error);
        }
      };
    
  useEffect(() => {
    const tempId = user > admins[0].ID ? user + admins[0].ID : admins[0].ID + user;
    setCombinedId(tempId);

    const unsub = onSnapshot(doc(db, "messages", tempId), async (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });
    return () => {
      unsub();
    };

  }, []);
  

  
  useEffect(() => {
    console.log("Message changes: ", messages);
    // messagesEndRef?.current?.scrollIntoView();
  }, [messages]);
    console.log('admins',admins)
    return (
        <>
            <div className="contentright">
                <div className='chat-box'>
                    <div className='margin'>
                        <div className='display-msg'>
                            <button onClick={() => closeChat()} className="close-chat-btn">
                                <TiDeleteOutline /></button>
                        </div>
                        <div className='bottom'>
                            <input
                                className='input-msg'
                                type="text"
                                placeholder="Enter Your Message" 
                                value={message} // Bind the input value to the message state
                                onChange={(event)=>setMessage(event.target.value)} // Handle input changes
                                />
                            <button
                                className='send-msg'
                            ><IoSend onClick={handleSendMessage} /></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ChatModal;