import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IHeaderInfo } from '../../interface/chat.interface';
interface listChats {
  displayName: string;
  lastMessage: string;
  uid: string;
}

interface IProps {
  setChatId: (data: string) => void;
  setHeaderInfo: (data: IHeaderInfo) => void;
}
const ChatPeople = (props: IProps) => {
  const selectedRole = localStorage.getItem('selectedRole');
  const { setChatId, setHeaderInfo } = props;
  const [chats, setChats] = useState<any>();
  const [selectedChatId, setSelectedChatId] = useState<string>('');
  const user = useSelector((state: RootState) => state.auth.User);
  const db = getFirestore();

  const getChatsForBanquets = () => {
    const unsubscribes: any = [];

    user?.banquet_ids?.forEach((id) => {
      const unsubscribe = onSnapshot(doc(db, 'listing', id), (snapshot) => {
        console.log('data', snapshot.data());
        // Handle the data as needed, such as updating state
        setChats({ ...chats, ...snapshot.data() });
      });
      unsubscribes.push(unsubscribe);
    });

    return () => {
      unsubscribes?.forEach((unsub: any) => unsub());
    };
  };
  useEffect(() => {
    if (selectedRole === 'admin') {
      getChatsForBanquets();
    } else {
      const getChats = () => {
        const unsub = onSnapshot(
          doc(db, 'listing', user?.id as string),
          (doc) => {
            setChats(doc.data());
          }
        );

        return () => {
          unsub();
        };
      };

      if (selectedRole !== 'admin') {
        user?.id && getChats();
      } else {
        user?.id && getChatsForBanquets();
      }
    }
  }, [user]);
  return (
    <>
      <div className="p-3 border border-secondary rounded-2 vh-100">
        <h3 className="mb-3">Chat People</h3>
        {chats &&
          Object?.keys(chats)?.map((key) => {
            const item = chats[key];
            return (
              <Button
                key={item.uid}
                className={`PeopleName ${
                  selectedChatId === key ? 'bg-primary' : ''
                }`}
                onClick={() => {
                  setChatId(key);
                  setHeaderInfo(item);
                  setSelectedChatId(key);
                }}
              >
                {selectedRole === 'admin'
                  ? item.displayName
                  : item.displayBanquet}
                <span className="d-block ms-3 fw-light">
                  {item.lastMessage.messageTemp}
                </span>
              </Button>
            );
          })}
        {/* <div className="ChatPeople">
          {chats?.map((item) => (
            <Button className="PeopleName">
              {item.displayName}
              <span className=" d-block ms-3 fw-light">{item.lastMessage}</span>
            </Button>
          ))}
        </div> */}
      </div>
    </>
  );
};

export default ChatPeople;
