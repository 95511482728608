import {
  Decor,
  IBookingForm,
  IVenueData,
} from "../../interface/form.interface";
import "./index.css";
import UserHeader from "../../components/header/UserHeader";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { IoChevronBack } from "react-icons/io5";
import "bootstrap/dist/css/bootstrap.min.css";
import { CiDollar } from "react-icons/ci";
import { LuFileType } from "react-icons/lu";
import { MdLunchDining, MdOutlineReduceCapacity } from "react-icons/md";
import { GiChickenOven, GiSheep } from "react-icons/gi";
import { TbServicemark } from "react-icons/tb";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { banquetSvc } from "../../service/banquet.service";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { Value } from "react-calendar/dist/cjs/shared/types";
import Calendar from "react-calendar";
import { toast } from "react-toastify";

const VanueDetailModal = () => {
  const user = useSelector((state: RootState) => state.auth?.User);
  const location = useLocation();
  const { data } = location.state;

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setShowModal(true);
  };
  const navigate = useNavigate();
  const [slotsArray, setSlotsArray] = useState<string[]>([]);
  const [time, setTime] = useState<Value>(new Date());
  const onChange = (timeData: Value) => {
    console.log(timeData);
  };
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<IBookingForm>();
  const onSubmit = async (formData: IBookingForm) => {
    console.log("Form Data", formData);
    if (data) {
      const res = await banquetSvc.banquetBooking(formData, data?.ID);
      if (res) {
        toast.info("Your Booking has been created");
      }
    }
    reset();
    setValue("eventDate", moment().format("DD/MM/YYYY"));
    setTime(new Date());
  };
  useEffect(() => {
    let slots: string[] = [];
    data?.Slots.map((slotsString: string, index: number) => {
      slotsString.split(",").map((item) => {
        slots.push(item);
      });
    });
    setSlotsArray(slots);
    setValue("eventDate", moment().format("DD/MM/YYYY"));
  }, []);

  return (
    <>
      <div className="top-0 header-index">
        <UserHeader />
      </div>
      <div className="VanueDetailModal">
        <div style={{ width: "100%" }}>
          <div>
            <img src={data.Images?.[0]} alt="" className="bigger-image" />
            <div className="image-title">
              <h2>{data.Name}</h2>
            </div>
          </div>
        </div>
        <div className="">
          <div className="d-flex flex-row justify-content-center">
            <div
              className="d-flex flex-column "
              style={{ marginRight: "20px" }}
            >
              <div
                className="d-flex shadow flex-column info-color mb-2 "
                style={{
                  width: "450px",
                  height: "310px",
                  borderRadius: 12,
                }}
              >
                <p className="mb-2 p-1 m-1 ">
                  <CiDollar />
                  <strong>Base Price</strong> {data.BasePrice}
                </p>
                <p className="mb-2 p-1 m-1">
                  <LuFileType />
                  <strong>Type</strong> {data.Type}
                </p>
                <p className="mb-2 p-1 m-1">
                  <MdOutlineReduceCapacity />
                  <strong>People's Capacity</strong> {data.MinCapacity} -{" "}
                  {data.MaxCapacity}
                </p>
                <p className="mb-2 p-1 m-1">
                  <GiChickenOven />
                  <strong>Chicken Price</strong> {data.ChickenPrice}{" "}
                </p>
                <p className="mb-2 p-1 m-1">
                  <GiSheep />
                  <strong>Mutton Price</strong> {data.MuttonPrice}
                </p>
                <p className="mb-2 p-1 m-1 facilities-container">
                  <TbServicemark /> <strong>Facilities</strong>
                  <ul>
                  {data.Facilities.map((item: string, index: number) => 
                  item.split(",").map((subItem:string,index:number)=>(
                    <li key={index} className=" me-1">
                    {subItem}
                  </li>
                  ))

                  )}{" "}
                  </ul>
                </p>
                <p className="slots-container p-1 m-1">
                  <MdLunchDining /> <strong>Slots</strong>
                  
                  {data.Slots.map((item: string) =>
                    item
                      .split(",")
                      .map((subItem: string, index: number) => (
                        <li key={index}>{subItem.trim()}</li>
                      ))
                  )}
                </p>
              </div>
              <div
                style={{
                  width: "450px",
                }}
                className="d-flex flex-row flex-wrap"
              >
                {data.Images?.map((item: any, index: any) => (
                  <div
                    key={index}
                    style={{
                      cursor: "pointer",
                      width: "220px",
                      marginLeft: "4px",
                    }}
                  >
                    <img
                      className={`mb-1`}
                      src={item}
                      alt={`Venue ${index + 1}`}
                      onClick={() => handleImageClick(item)}
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "150px",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div
              className="shadow p-3"
              style={{
                width: user?.isSelectedRole === "customer" ? "400px" : "300px",
                background: "whitesmoke",
                height: user?.isSelectedRole === "customer" ? "auto" : "130px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 12,
              }}
            >
              {user?.isSelectedRole === "customer" ? (
                <form onSubmit={handleSubmit(onSubmit)} className="banquetForm">
                  <div className="row mb-3">
                    <div className="col">
                      <input
                        placeholder="Enter First Name"
                        className="inputWidth"
                        autoComplete="off"
                        defaultValue={user?.name.split(" ")[0]}
                        {...register("firstName", {
                          required: "Your Name is Required",
                          minLength: {
                            value: 2,
                            message: "Your Name is Required",
                          },
                        })}
                      />
                      {errors.firstName && (
                        <span className="loginErrorMessage">
                          {errors.firstName.message}
                        </span>
                      )}
                    </div>
                    <div className="col">
                      <input
                        placeholder="Enter Last Name"
                        className="inputWidth"
                        autoComplete="off"
                        defaultValue={user?.name.split(" ")[1]}
                        {...register("lastName", {
                          required: "Your Name is Required",
                          minLength: {
                            value: 2,
                            message: "Your Name is Required",
                          },
                        })}
                      />
                      {errors.lastName && (
                        <span className="loginErrorMessage">
                          {errors.lastName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group inputmaxWidth">
                    <input
                      placeholder="Enter Email"
                      className="inputWidth"
                      defaultValue={user?.email}
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="loginErrorMessage">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <Calendar
                      onChange={(date: Value) => {
                        setTime(date);
                        if (date) {
                          setValue(
                            "eventDate",
                            moment(date.toString()).format("DD/MM/YYYY")
                          );
                        }
                      }}
                      value={time}
                      minDate={new Date()}
                    />
                  </div>

                  {slotsArray?.length > 0 && (
                    <>
                      <select
                        {...register("eventSlot")}
                        className="selectOption"
                        defaultValue={slotsArray[0]}
                      >
                        {slotsArray?.map((item) => (
                          <option value={item} className="form-label p-5">
                            {item}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  {data?.Decor && (
                    <>
                      <select
                        {...register("decor")}
                        className="selectOption"
                        defaultValue={data.Decor[0].Type}
                      >
                        {data?.Decor?.map((item: Decor) => (
                          <option value={item.Type} className="form-label p-5">
                            {item.Type}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  <p className="">
                    {`Guest Capacity:(${data?.MinCapacity}-${data?.MaxCapacity})`}
                  </p>
                  <Controller
                    name="numberOfGuests"
                    control={control}
                    defaultValue={data?.MinCapacity} // Initial value
                    render={({ field }) => (
                      <>
                        <div className="form-group inputmaxWidth">
                          <input
                            {...field}
                            type="number"
                            min={data?.MinCapacity}
                            max={data?.MaxCapacity}
                            className="inputWidth"
                          />
                          {field.value > (data?.MaxCapacity as number) ||
                          field.value < (data?.MinCapacity as number) ? (
                            <span className="loginErrorMessage">
                              Please Choose between provided Capacity
                            </span>
                          ) : null}
                        </div>
                      </>
                    )}
                  />
                  <div className="form-group inputmaxWidth mt-3">
                    <input
                      placeholder="Enter Requested Price"
                      type="number"
                      className="inputWidth"
                      {...register("requestedPrice", {
                        required: "Requested Price is required",
                        min: {
                          value: 1,
                          message:
                            "Requested Price should be greater than or equal to 1",
                        },
                      })}
                    />
                    {errors.requestedPrice && (
                      <span className="loginErrorMessage">
                        {errors.requestedPrice.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group inputmaxWidth">
                    <input
                      placeholder="Enter Message"
                      className="inputWidth"
                      autoComplete="off"
                      {...register("message")}
                      type="text-ares"
                    />
                    {errors.message && (
                      <span className="loginErrorMessage">
                        {errors.message.message}
                      </span>
                    )}
                  </div>
                  <button type="submit" className="buttonSubmit">
                    Create Booking
                  </button>
                </form>
              ) : (
                <div className="text-center">
                  <p>Please login to create a booking.</p>
                  <div className="d-flex align-items-center justify-content-center gap-3">
                    <Button
                      className="btnRgstr"
                      onClick={() => navigate("/signup")}
                    >
                      Register
                    </Button>
                    <Button
                      className="btnLogin"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img
                className="img-fluid w-100 h-100"
                src={selectedImage}
                alt="Selected Venue"
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default VanueDetailModal;
