import { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import "./index.css";
import { banquetSvc } from "../../service/banquet.service";
import { useNavigate } from "react-router-dom";
import { cityOptions } from "../../constants/data";
import { IBanquet, Admin } from "../../interface/banquetDetail.interface";
import LoaderSpinner from "../../components/Loader/Loader";
import { mapsSvc } from "../../service/maps.service";
import ChatModal from "../../components/ChatModal/ChatModal";
import { Button } from "react-bootstrap";
import BeforeLogin from "../../components/Modals/BeforeLogin";
import PrimaryHeader from "../../components/Headers/PrimaryHeader";
import SecondaryHeader from "../../components/Headers/SecondaryHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { salonSvc } from "../../service/salon.service";
import SearchAndSlideBanner from "../../components/HeaderComponent/SearchAndSlideBanner";
import { IStudio, Studio } from "../../interface/studioDetail.interface";
import { ISaloon, Salon } from "../../interface/saloonDetails.interface";


interface CityOption {
  label: string;
  value: string;
}

const SaloonScreen: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.User);
  const [index, setIndex] = useState<number>(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedCity, setSelectedCity] = useState<null | CityOption >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [saloons, setSaloons] = useState<ISaloon>();
  const [searchSaloons, setSearchSaloons] = useState<string>("");
  const searchTimeRef = useRef<NodeJS.Timeout | null>(null);
  const [position, setPosition] = useState<{
    latitude: number;
    longitude: number;
  }>({ latitude: 11, longitude: 11 });
  const [isChecked, setIsChecked] = useState<number>(0);
  const [chatModalView, setChatModalView] = useState<boolean>(false);
  const [adminIds, setAdminIds] = useState<Admin[]>();
  const [banquetId, setBanquetId] = useState<string>("");
  const [banquetName, setBanquetName] = useState<string>("");
  const closeChatModal = () => {
    setChatModalView(false);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchSaloons(event.target.value);
    if (searchTimeRef.current) {
      clearTimeout(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(async () => {
      handleSearch("", 0, selectedCity?.value, search);
    }, 300);
  };

  const onRangeChange = async (range: number, city: string = "") => {
    try {
      setLoading(true);
      setSelectedCity(null);
      setIsChecked(range);
      // if (searchTimeRef.current) {
      //   clearTimeout(searchTimeRef.current);
      // }
      if (range !== 0) {
        // searchTimeRef.current = setTimeout(async () => {
          let latitude: number = 0,
            longitude: number = 0;
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
              latitude = position.coords.latitude;
              longitude = position.coords.longitude;
              console.log(latitude, longitude);
              city = await mapsSvc.getCityFromCoordinates(latitude, longitude);
              // Now that you have latitude, longitude, and city, you can make the service call
              console.log("lat,long", latitude, longitude);
              const resp = await salonSvc.getSaloons(
                city,
                latitude,
                longitude,
                range,
                searchSaloons
              );
              if (resp) {
                setSaloons(resp as ISaloon);
              }
              setLoading(false);
            });
          }
        // }, 300);
      } else {
        const resp = await salonSvc.getSaloons(
          city,
          0,
          0,
          range,
          searchSaloons
        );
        if (resp) {
          setSaloons(resp as ISaloon);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const onClearInput = () => {
    setSearchSaloons("");
    handleSearch("", 0, selectedCity?.value, "");
    // getData("");
  };

  const navigation = useNavigate();

  const handleCityChange = async (selectedCity: CityOption) => {
    console.log('value',selectedCity)
    if (selectedCity) {
      const {value,label} = selectedCity;
      setSelectedCity(selectedCity);
      setIsChecked(0);
      const resp = await salonSvc.getSaloons(value, 0, 0, 0, searchSaloons);
      setSaloons(resp as ISaloon);
    }
  };

  const handleSearch = async (
    value: string,
    range: number = 0,
    city: string = "",
    searchSaloons: string = ""
  ) => {
    try {
      setLoading(true);
      //   setIsChecked(0);
      if (searchTimeRef.current) {
        clearTimeout(searchTimeRef.current);
      }
      searchTimeRef.current = setTimeout(async () => {
        let latitude: number = 0,
          longitude: number = 0;
        //   if (navigator.geolocation) {
        const resp = await salonSvc.getSaloons(
          city,
          latitude,
          longitude,
          range,
          searchSaloons
        );
        setSaloons(resp as ISaloon);

        //   }
      }, 300);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch("", 0, selectedCity?.value, searchSaloons);
  }, []);

  return (
    <>
      {loading && <LoaderSpinner />}
      <PrimaryHeader />
      <SecondaryHeader />
      <SearchAndSlideBanner
        cityOptions={cityOptions}
        searchBanquets={searchSaloons}
        isChecked={isChecked as any}
        index={index}
        handleCityChange={handleCityChange}
        onInputChange={onInputChange}
        onClearInput={onClearInput}
        onRangeChange={onRangeChange}
        handleSelect={handleSelect}
        selectedCity={selectedCity}
        bannerText="Explore Salons"
      />
      <section className="data-list">
        {saloons?.Salons?.map((studio: Salon) => (
          <div className="data-box" key={studio.ID}>
            <img
              src={
                "https://mobile-cuisine.com/wp-content/uploads/2022/03/wedding-table-gf805441aa_1280.jpg"
              }
              alt=""
              className="data-box-image"
            />
            <h3 className="data-box-h3">{studio.Name}</h3>
            <p>
              Location: {studio.Location.City}, {studio.Location.Area}
            </p>
            <p>Description: {studio.Description}</p>
            <div>
              <BeforeLogin
                show={modalShow}
                onHide={() => setModalShow(false)}
                banquetId={banquetId}
                banquetName={banquetName}
              />
            </div>
          </div>
        ))}
      </section>
      {chatModalView && (
        <div className="modal-backdrop-default">
          <ChatModal admins={adminIds as Admin[]} closeChat={closeChatModal} />
        </div>
      )}
      <footer></footer>
    </>
  );
};

export default SaloonScreen;
