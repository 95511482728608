import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { hallSvc } from '../../service/hall.service';
import { IVenueData } from '../../interface/form.interface';
import './index.css';
import VenueModal from '../../components/VenueModal/VenueModal';
import FormModal from '../../components/FormModal/FormModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import ChatModal from '../../components/ChatModal/ChatModal';
import BookingForm from '../../components/BookingForm/BookingForm';
import DecorModal from '../../components/DecorModal/DecorModal';
import { logout } from '../../service/auth.service';
import { BiSolidError } from 'react-icons/bi';
import PrimaryHeader from '../../components/Headers/PrimaryHeader';
import SecondaryHeader from '../../components/Headers/SecondaryHeader';

const HallsScreen = () => {
  const selectedRole = localStorage.getItem('selectedRole');
  const [venue, setVenue] = useState<IVenueData[]>([]);
  const [singleVenue, setSingleVenue] = useState<IVenueData | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formModalView, setFormModalView] = useState<boolean>(false);
  const [decorModalView, setDecorModalView] = useState<boolean>(false);
  const [chatModalView, setChatModalView] = useState<boolean>(false);
  const [banquetBookingForm, setBanquetBookingForm] = useState<boolean>(false);
  const [venueId, setVenueId] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.auth.User);

  const { banquetId, banquetName, banquet } = location.state || {};
  console.log('banq', banquet);
  const getData = async () => {
    if (banquetId) {
      const res = await hallSvc.getHalls(banquetId);
      if (res) {
        console.log(res.venues);
        setVenue(res.venues);
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const closeChatModal = () => {
    setChatModalView(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setSingleVenue(null);
    getData();
  };

  const viewChat = () => {
    setChatModalView(true);
  };
  const viewVenue = (data: IVenueData) => {
    setSingleVenue(data);
    navigate('/VanueModal', { state: { data } });
  };

  const editBanquet = (venue: IVenueData) => {
    // setShowModal(false);
    setSingleVenue(venue);
    navigate('/NewVenue', {
      state: { banquetId: banquetId, hallData: venue },
    });
    // setFormModalView(true);
  };
  const bookingRequest = (venue: IVenueData) => {
    setSingleVenue(venue);
    setBanquetBookingForm(true);
  };

  useEffect(() => {
    if (!location.state) {
      navigate(-1);
      return;
    }
  }, []);
  return (
    <>
      <PrimaryHeader />
      <SecondaryHeader banquetId={banquetId} banquetName={banquetName} />

      <div className="BanquetCard">
        <img
          className="banquetimg"
          src={
            banquet?.Images?.[0] ||
            'https://mobile-cuisine.com/wp-content/uploads/2022/03/wedding-table-gf805441aa_1280.jpg'
          }
          alt="Banquet"
        />
      </div>
      <p>Banquet Name:{banquet?.Name}</p>
      <p>Parking Capacity: {banquet?.ParkingCapacity}</p>
      <p>Contact: {banquet?.Contact?.ContactNumber}</p>
      <p>Email: {banquet?.Contact?.Email}</p>
      <div>
        <div className="banquet-register">
          {selectedRole === 'admin' && (
            <button
              className="booking-button"
              onClick={() =>
                navigate('/NewVenue', {
                  state: { banquetId: banquetId, hallData: {} },
                })
              }
            >
              + Register A New Venue
            </button>
          )}
        </div>
        <section className="data-list">
          {!venue && (
            <div className="nodata-error">
              <BiSolidError />
              <h2>No Data Found</h2>
            </div>
          )}
        </section>
        <section className="data-list">
          {venue?.map((venue, index) => (
            <div key={index} className="data-box">
              <img
                className="data-box-image"
                src={
                  venue.Images?.length
                    ? venue?.Images[0]
                    : 'https://mobile-cuisine.com/wp-content/uploads/2022/03/wedding-table-gf805441aa_1280.jpg'
                }
                alt={`Banquet ${index + 1}`}
              />

              <h2 className="data-box-h2">{venue.Name}</h2>
              <p>Base Price: {venue.BasePrice}</p>
              <p>Type: {venue.Type}</p>
              <div
                style={{
                  width: '100%',
                  // display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={() => viewVenue(venue)}
                  >
                    {user?.isSelectedRole === 'customer'
                      ? ' View & Booking'
                      : 'View'}
                  </button>
                  {selectedRole === 'admin' ? (
                    <button
                      className="btn btn-secondary"
                      onClick={() => editBanquet(venue)}
                    >
                      Edit
                    </button>
                  ) : null}
                </div>
                {/* <Link to="/HallBookingForm">New Booking</Link> */}

                {/* <Link to="/Conversation"> */}
                {/* </Link> */}
              </div>
            </div>
          ))}
        </section>
        {banquetBookingForm && (
          <div className="modal-backdrop-default">
            <BookingForm
              closeModal={() => setBanquetBookingForm(false)}
              hallData={singleVenue}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default HallsScreen;
