import { doc, getFirestore, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { app } from "./firebaseConfig";

const db = getFirestore(app);

export async function addDataToFirebase(docId:string, data:any, collection:any, merge:boolean) {
  console.log(data);
  try {
    const res = await setDoc(doc(db, collection, docId), data, {
      merge: merge,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function updateDataToFirebase(docId:string, data:any, collection:any, merge:boolean) {

  try {
    const res = await setDoc(doc(db, collection, docId), data, {
      merge: merge,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function addDataToArrayFirebase(docId:string, data:any){
  try {
    const res =  await updateDoc(doc(db, "messages", docId), {
      messages: arrayUnion(data),
    },
    );
    return res;
  } catch (error) {
    console.log(error);    
  }
}

export async function addNotificationToArrayFirebase(docId:string, data:any){
  try {
    const res =  await updateDoc(doc(db, "notifications", docId), {
      data: arrayUnion({...data}),
    },);
    return res;
  } catch (error) {
    console.log(error);    
  }
}

