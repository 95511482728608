import { ChangeEvent, useEffect, useRef, useState } from 'react';
import './index.css';
import { logout } from '../../service/auth.service';
import { banquetSvc } from '../../service/banquet.service';
import { useNavigate } from 'react-router-dom';
import { cityOptions, pakCities } from '../../constants/data';
import { IBanquet, Admin } from '../../interface/banquetDetail.interface';
import LoaderSpinner from '../../components/Loader/Loader';
import { mapsSvc } from '../../service/maps.service';
import { CiLogout } from 'react-icons/ci';
import { MdCancel } from 'react-icons/md';
import ChatModal from '../../components/ChatModal/ChatModal';
import { fbLogOut } from '../../service/firebaseAuthService.service';
import { Button, Carousel, Col, Form, Row } from 'react-bootstrap';
import BeforeLogin from '../../components/Modals/BeforeLogin';
import PrimaryHeader from '../../components/Headers/PrimaryHeader';
import SecondaryHeader from '../../components/Headers/SecondaryHeader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { BanquetCard } from '../../components/Banquets/BanquetCard';
import Select from 'react-select';
import { setIsSelected } from '../../features/Auth Slice/authSlice';
import { IUserData } from '../../interface/IUserData.interface';

interface CityOption {
  label: string;
  value: string;
}

const CustomerScreen: React.FC = () => {
  const user: IUserData | null = useSelector(
    (state: RootState) => state.auth.User
  );
  const [index, setIndex] = useState<number>(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedCity, setSelectedCity] = useState<CityOption | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [banquets, setBanquets] = useState<IBanquet[]>([]);
  const [searchBanquets, setSearchBanquets] = useState<string>('');
  const searchTimeRef = useRef<NodeJS.Timeout | null>(null);
  const [position, setPosition] = useState<{
    latitude: number;
    longitude: number;
  }>({ latitude: 11, longitude: 11 });
  const [isChecked, setIsChecked] = useState<number>(0);
  const [chatModalView, setChatModalView] = useState<boolean>(false);
  const [adminIds, setAdminIds] = useState<Admin[]>();
  const [banquetId, setBanquetId] = useState<string>('');
  const [banquetName, setBanquetName] = useState<string>('');
  const closeChatModal = () => {
    setChatModalView(false);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(0);
    setSelectedCity(null);
    setSearchBanquets(event.target.value);
    if (searchTimeRef.current) {
      clearTimeout(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(async () => {
      getData(event.target.value);
    }, 300);
  };
  const onRangeChange = async (range: number) => {
    setIsChecked(range);
    setSelectedCity(null);
    setSearchBanquets('');
    let latitude: number = 0,
      longitude: number = 0;
    if (range !== 0) {
      if (navigator.geolocation) {
        setLoading(true);
        await navigator.geolocation.getCurrentPosition(async function async(
          position
        ) {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
          console.log(latitude, longitude);
          const city = await mapsSvc.getCityFromCoordinates(
            latitude,
            longitude
          );
          const resp = await banquetSvc.getBanquetsByArea(
            city,
            latitude,
            longitude,
            range
          );
          if (resp) {
            setBanquets(resp.banquets);
          }
          setLoading(false);
        });
      }
    } else {
      getData('');
    }
  };

  const onClearInput = () => {
    setSearchBanquets('');
    getData('');
  };

  const navigation = useNavigate();

  const handleCityChange = async (selectedCity: CityOption) => {
    if (selectedCity) {
      const { value, label } = selectedCity;
      setSelectedCity(selectedCity);
      setSearchBanquets('');
      setIsChecked(0);
      console.log('City: ', value);
      const resp = await banquetSvc.getBanquetByCity(value);
      if (resp) {
        console.log('response: ', resp);
        setBanquets(resp.banquets);
      }
    }
  };
  const getData = async (value: string) => {
    try {
      const resp = await banquetSvc.getCustomerBanquets(value);
      if (resp) {
        setBanquets(resp.banquets);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    getData('');
  }, []);

  return (
    <>
      {loading && <LoaderSpinner />}
      <PrimaryHeader />
      <SecondaryHeader />
      <section className="search-section">
        <div className="filter-container">
          <h1>Explore Banquets</h1>
          <div className="d-flex flex-direction-row justify-content-center align-items-center gap-3">
            <Select
              value={selectedCity}
              options={cityOptions}
              onChange={(e: any) => handleCityChange(e)}
              placeholder="Search By City"
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '200px', // Set your desired width here
                }),
              }}
            />

            <div>
              <input
                className="name-filter"
                value={searchBanquets}
                onChange={onInputChange}
                placeholder="Search By Name"
              />
              {searchBanquets.length >= 1 ? (
                <button className="search-clear-btn" onClick={onClearInput}>
                  <MdCancel />
                </button>
              ) : null}
            </div>
            <div>
              <Form.Select
                aria-label="Default select example"
                onChange={(ch) => onRangeChange(parseInt(ch.target.value))}
                value={isChecked}
              >
                <option value="0">Nearby</option>
                <option value="5">5 Km</option>
                <option value="10">10 Km</option>
                <option value="15">15 Km</option>
                <option value="20">20 Km</option>
                <option value="25">25 Km</option>
              </Form.Select>
            </div>
          </div>
        </div>
      </section>

      <section className="BanquetsDtls p-4 d-flex justify-content-center">
        {banquets?.map((banquet) => (
          <>
            <Row className="g-3">
              <Col md={4}>
                <BanquetCard banquet={banquet} />
              </Col>
            </Row>
          </>
        ))}
      </section>
      {chatModalView && (
        <div className="modal-backdrop-default">
          <ChatModal admins={adminIds as Admin[]} closeChat={closeChatModal} />
        </div>
      )}
      <footer></footer>
    </>
  );
};

export default CustomerScreen;
