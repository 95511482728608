import { createSlice, current } from '@reduxjs/toolkit';
import { IUser } from '../../interface/IUser.interface';
const initialState: IUser = {
  User: {
    id: '',
    contact: '',
    email: '',
    exp: 0,
    name: '',
    role: [],
    banquet_ids: [],
    isSelectedRole: '',
  },
  isSelected: '',
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      // console.log('In Redux: ', action.payload);
      state.User = action.payload;
      state.isLoggedIn = true;
    },
    removeUser: (state) => {
      localStorage.clear();
      state.User = null;
      state.isLoggedIn = false;
      state.isSelected = '';
    },
    loadUser: (state) => {
      const user = JSON.parse(localStorage.getItem('user')!);
      if (user) {
        state.User = user;
        state.isLoggedIn = true;
      }
    },
    setIsSelected: (state, action) => {
      if (state.User) {
        state.User.isSelectedRole = action.payload;
      }
    },
  },
});

export const { setAuthUser, removeUser, loadUser, setIsSelected } =
  authSlice.actions;

export default authSlice.reducer;
