import { useSelector } from 'react-redux';
import { IBookingForm, IVenueData } from '../../interface/form.interface';
import './index.css';
import { RootState } from '../../store/store';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { useEffect, useState } from 'react';
import { banquetSvc } from '../../service/banquet.service';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

interface IProps {
  closeModal: Function;
  hallData: IVenueData | null;
}
const BookingForm = (props: IProps) => {
  const { hallData, closeModal } = props;
  const user = useSelector((state: RootState) => state.auth.User);
  const [slotsArray, setSlotsArray] = useState<string[]>([]);
  const [setDate, setSelectedDate] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<IBookingForm>();
  const onSubmit = async (data: IBookingForm) => {
    if (hallData) {
      const res = await banquetSvc.banquetBooking(data, hallData?.ID);
    }
    closeModal();
    // console.log('Date Here', data);

    reset();
  };
  useEffect(() => {
    let slots: string[] = [];
    hallData?.Slots.map((slotsString, index) => {
      slotsString.split(',').map((item) => {
        slots.push(item);
      });
    });
    setSlotsArray(slots);
  }, []);

  const onDateChange = (date: any) => {
    const formattedDate = moment(date).format('DD/MM/YYYY');
    console.log(formattedDate);
    setValue('eventDate', formattedDate);
  };

  return (
    <div className="banquetModal">
       <div className="modal-header">
        <h5 className="modal-title">
        Booking Form Details
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => closeModal()}
        ></button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="banquetForm">
        {/* <h2>Booking Form Details</h2> */}
        <div className="form-group inputmaxWidth">
          <label className="labelText">First Name</label>
          <input
            placeholder="Enter First Name"
            className="inputWidth"
            autoComplete="off"
            defaultValue={user?.name.split(' ')[0]}
            {...register('firstName', {
              required: 'Your Name is Required',
              minLength: {
                value: 2,
                message: 'Your Name is Required',
              },
            })}
          />
          {errors.firstName && (
            <span className="loginErrorMessage">
              {errors.firstName.message}
            </span>
          )}
        </div>
        <div className="form-group inputmaxWidth">
          <label className="labelText">Last Name</label>
          <input
            placeholder="Enter Last Name"
            className="inputWidth"
            autoComplete="off"
            defaultValue={user?.name.split(' ')[1]}
            {...register('lastName', {
              required: 'Your Name is Required',
              minLength: {
                value: 2,
                message: 'Your Name is Required',
              },
            })}
          />
          {errors.lastName && (
            <span className="loginErrorMessage">{errors.lastName.message}</span>
          )}
        </div>
        <div className="form-group inputmaxWidth">
          <label className="labelText">Email</label>
          <input
            placeholder="Enter Email"
            className="inputWidth"
            defaultValue={user?.email}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && (
            <span className="loginErrorMessage">{errors.email.message}</span>
          )}
        </div>
        <div className="form-group">
          <label className="labelText">Select a Date</label>
          <DatePicker
            value={setDate}
            onChange={(date: any) => {
              onDateChange(date);
              setSelectedDate(date);
            }}
            minDate={new Date()}
            yearPlaceholder="yyyy"
            monthPlaceholder="mm"
            dayPlaceholder="dd"
          />
        </div>

        {slotsArray?.length > 0 && (
          <>
            <label className="labelText">Select Time</label>
            <select
              {...register('eventSlot')}
              className="selectOption"
              defaultValue={slotsArray[0]}
            >
              {slotsArray?.map((item) => (
                <option value={item} className="form-label p-5">
                  {item}
                </option>
              ))}
            </select>
          </>
        )}
        {hallData?.Decor && (
          <>
            <label className="labelText">Select Decor</label>
            <select
              {...register('decor')}
              className="selectOption"
              defaultValue={hallData.Decor[0].Type}
            >
              {hallData?.Decor?.map((item) => (
                <option value={item.Type} className="form-label p-5">
                  {item.Type}
                </option>
              ))}
            </select>
          </>
        )}
        <label className="labelText">Total Number of Guests</label>
        <p className=''>
          {
            `Guest Capacity:(${hallData?.MinCapacity}-${hallData?.MaxCapacity})` 
          }
        </p>
        <Controller
          name="numberOfGuests"
          control={control}
          defaultValue={hallData?.MinCapacity} // Initial value
          render={({ field }) => (
            <>
              <input
                {...field}
                type="number"
                min={hallData?.MinCapacity}
                max={hallData?.MaxCapacity}
                className="inputWidth"
                />
              {field.value > ( hallData?.MaxCapacity as number) || field.value < (hallData?.MinCapacity as number) ? <p>Please Choose between provided Capacity</p>:null}
            </>
          )}
        />
        <div className="form-group inputmaxWidth">
          <label className="labelText">Requested Price</label>
          <input
            placeholder="Enter Requested Price"
            type="number"
            className="inputWidth"
            {...register('requestedPrice', {
              required: 'Requested Price is required',
              min: {
                value: 1,
                message: 'Requested Price should be greater than or equal to 1',
              },
            })}
          />
          {errors.requestedPrice && (
            <span className="loginErrorMessage">
              {errors.requestedPrice.message}
            </span>
          )}
        </div>
        <div className="form-group inputmaxWidth">
          <label className="labelText">Message</label>
          <input
            placeholder="Enter Message"
            className="inputWidth"
            autoComplete="off"
            {...register('message')}
          />
          {errors.message && (
            <span className="loginErrorMessage">{errors.message.message}</span>
          )}
        </div>
        <button type="submit" className="buttonSubmit">
          Create Booking
        </button>
      </form>
      <button onClick={() => closeModal()} className="close-modal-btn">
        Close Modal
      </button>
    </div>
  );
};

export default BookingForm;
