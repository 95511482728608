import { useEffect, useState } from 'react';
import { logout } from '../../service/auth.service';
import './index.css';
import {
  IMainBooking,
  IMainVenueBooking,
} from '../../interface/booking.interface';
import { bookingSvc } from '../../service/booking.service';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { CiLogout } from 'react-icons/ci';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import UserHeader from '../../components/header/UserHeader';
const BookingScreen = () => {
  const user = useSelector((state: RootState) => state.auth?.User);
  const [bookingData, setBookingData] = useState<IMainBooking>();
  const [adminBooking, setAdminBooking] = useState<IMainVenueBooking>();
  const location = useLocation();
  const navigation = useNavigate();
  const { banquetId } = location.state || {};

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (banquetId) {
      const res = await bookingSvc.getBookingsById(banquetId);
      if (res) {
        setAdminBooking(res);
      }
    } else {
      const res = await bookingSvc.getuserBookings();
      if (res) {
        setBookingData(res);
      }
    }
  };
  const respondBooking = async (id: string, offer: string) => {
    const res = await bookingSvc.responseBooking(id, offer);
    if (res) {
      getData();
    }
  };

  return (
    <div>
      <UserHeader />
      <section className="data-list">
        {user?.isSelectedRole === 'admin' ? (
          <table className="table table-bordered table-striped table-hover">
            <thead className="thead-dark">
              <tr>
                <th>Admin</th>
                <th>Venue Name</th>
                <th>Event Date</th>
                <th>Event Slot</th>
                <th>Requested Date</th>
                <th>Number of Guests</th>
                <th>Decor</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {adminBooking?.booking?.map((item, index) => (
                <tr key={index}>
                  <td>Admin</td>
                  <td>{item.venueName}</td>
                  <td>
                    {moment(item.booking.EventDate, 'DD/MM/YYYY').format(
                      'D MMMM YYYY'
                    )}
                  </td>
                  <td>{item.booking.EventSlot}</td>
                  <td>
                    {moment.utc(item.booking.RequestedOn).format('D MMMM YYYY')}
                  </td>
                  <td>{item.booking.NumberOfGuests}</td>
                  <td>
                    {item.booking.Decor.Status
                      ? item.booking.Decor.Package
                      : ''}
                  </td>
                  <td>
                    {item.booking.Status === 'pending' ? (
                      <div className="banquet-actions">
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            respondBooking(item.booking.ID, 'accepted')
                          }
                        >
                          Accept
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() =>
                            respondBooking(item.booking.ID, 'rejected')
                          }
                        >
                          Reject
                        </button>
                      </div>
                    ) : (
                      <p>{item.booking.Status}</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <table className="table table-bordered table-striped table-hover">
            <thead className="thead-dark">
              <tr>
                <th>Banquet Name</th>
                <th>Event Date</th>
                <th>Event Slot</th>
                <th>Decor</th>
                <th>Requested Date</th>
                <th>Number of Guests</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {bookingData?.bookings?.map((item, index) => (
                <tr key={index}>
                  <td>{item.banquetName}</td>
                  <td>
                    {moment(item.booking.EventDate, 'DD/MM/YYYY').format(
                      'D MMMM YYYY'
                    )}{' '}
                  </td>
                  <td>{item.booking.EventSlot}</td>
                  <td>
                    {item.booking.Decor.Status
                      ? item.booking.Decor.Package
                      : ''}
                  </td>
                  <td>
                    {moment.utc(item.booking.RequestedOn).format('D MMMM YYYY')}
                  </td>
                  <td>{item.booking.NumberOfGuests}</td>
                  <td>{item.booking.Status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </section>
    </div>
  );
};

export default BookingScreen;
