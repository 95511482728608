import React from "react";
import { Carousel, Form } from "react-bootstrap";
import Select from "react-select";
import { MdCancel } from "react-icons/md";

interface CityOption {
  label: string;
  value: string;
}

interface Props {
  cityOptions: CityOption[];
  searchBanquets: string;
  isChecked: string;
  index: number;
  handleCityChange: (e: CityOption) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClearInput: () => void;
  onRangeChange: (value: number) => void;
  handleSelect: (selectedIndex: number, e: any) => void;
  selectedCity?: any;
  bannerText?:string
}

const SearchAndSlideBanner: React.FC<Props> = ({
  cityOptions,
  searchBanquets,
  isChecked,
  index,
  handleCityChange,
  onInputChange,
  onClearInput,
  onRangeChange,
  handleSelect,
  selectedCity,
  bannerText
}) => {
  return (
    <>
      <section className="search-section">
        <div className="filter-container">
          <h1>{bannerText}</h1>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <Select
              value={selectedCity}
              options={cityOptions}
              onChange={(e: any) => handleCityChange(e)}
              placeholder="Search By City"
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "200px", // Set your desired width here
                }),
              }}
            />

            <div>
              <input
                className="name-filter"
                value={searchBanquets}
                onChange={onInputChange}
                placeholder="Search By Name"
              />
              {searchBanquets.length >= 1 ? (
                <button className="search-clear-btn" onClick={onClearInput}>
                  <MdCancel />
                </button>
              ) : null}
            </div>
            <div>
              <Form.Select
                aria-label="Default select example"
                onChange={(ch) => onRangeChange(parseInt(ch.target.value))}
                value={isChecked}
              >
                <option value="0">Nearby</option>
                <option value="5">5 Km</option>
                <option value="10">10 Km</option>
                <option value="15">15 Km</option>
                <option value="20">20 Km</option>
                <option value="25">25 Km</option>
              </Form.Select>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="slide-banner-section">
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://venuehub.pk/wp-content/uploads/elementor/thumbs/WhatsApp-Image-2022-11-01-at-9.32.11-PM-px2w3i7u20h315mlgh1pvwbtd9j43mslfwgs6xb5d4.jpeg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://venuehub.pk/wp-content/uploads/elementor/thumbs/WhatsApp-Image-2022-11-12-at-11.33.11-AM-2-pxozc7xxen0yfctlve58jjhx0a8o20t1ivs6h5892g.jpeg"
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://venuehub.pk/wp-content/uploads/elementor/thumbs/WhatsApp-Image-2022-11-12-at-11.33.11-AM-1-1-pxozc9tlsb3j2kqvkeyhoj0u71zehf0i7535fp5gq0.jpeg"
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </section> */}
    </>
  );
};

export default SearchAndSlideBanner;
