import { useForm } from 'react-hook-form';
import { ISignUpForm } from '../../interface/form.interface';
import './index.css';
import { authLoginSocial, userRegister } from '../../service/auth.service';
import {
  signInWithFb,
  signUpWithFb,
} from '../../service/firebaseAuthService.service';
import { Button } from 'react-bootstrap';
import { FcGoogle } from 'react-icons/fc';
import { FacebookAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, facebookProvider, provider } from '../../service/firebaseConfig';
import { LiaFacebookF } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
const SignInForm = () => {
  const [selectedRole, setSelectedRole] = useState<string>('customer');
  const navgate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ISignUpForm>();
  // const onSubmit = async (data: ISignUpForm) => {
  //   console.log(data);
  //   try {
  //     const [signUpWithFbRes, userRegisterRes] = await Promise.allSettled([
  //       signUpWithFb({
  //         email: data.email,
  //         password: data.password,
  //         contact: data.contact,
  //         name: data.name,
  //         // role: sele

  //       }),
  //       signUpWithFb({email:data.email,password:data.password})
  //       // userRegister(data),
  //     ]);

  //     if (
  //       userRegisterRes.status === 'rejected' ||
  //       signUpWithFbRes.status === 'rejected'
  //     ) {
  //       window.alert('Failed to register user or sign up with Facebook');
  //     } else {
  //       console.log('signUpWithFbRes',signUpWithFbRes)
  //       // signIn
  //       // userRegister(,role)
  //       props.handleSignUp(false);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //     window.alert('An error occurred');
  //   }

  //   reset();
  //   // const res = await userRegister(data);
  //   // await signUpWithFb({email:data.email,password:data.password,role:data.role});
  //   // if (res) {
  //   //   props.handleSignUp(false);
  //   // } else {
  //   //   window.alert('User Already Present');
  //   // }
  //   reset();
  // };

  const onSubmit = async (data: ISignUpForm) => {
    console.log(data);
    try {
      const signUpWithFbRes = await signUpWithFb({
        email: data.email,
        password: data.password,
        contact: data.contact,
        name: data.name,
      });
      const userRegisters: any = await userRegister({
        email: data.email,
        name: data.name,
        contact: data.contact,
        role: selectedRole,
      });
      console.log('user Reg', userRegisters);
      if (userRegisters?.access_token) {
        toast.info('There is an already account exists with this email');
      } else {
        toast.success('Account Created Successfully');
      }
    } catch (error) {
      console.error('Error:', error);
      window.alert('An error occurred');
    }

    reset();
  };

  function facebookSignIn() {
    signInWithPopup(auth, facebookProvider)
      .then(async (data) => {
        console.log(
          'data',
          data.user.displayName,
          data.user.email,
          data.user.phoneNumber,
          selectedRole
        );
        const userRegisters: any = await userRegister({
          name: data.user.displayName as string,
          email: data.user.email as string,
          contact: data.user.phoneNumber as string,
          role: selectedRole,
        });
        console.log('user Reg', userRegisters);

        if (userRegisters?.access_token) {
          toast.info('There is an already account exists with this email');
        } else {
          toast.success('Account Created Successfully');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  function googleSignIn() {
    signInWithPopup(auth, provider)
      .then(async (data: any) => {
        console.log(
          'data',
          data.user.displayName,
          data.user.email,
          data.user.phoneNumber,
          selectedRole
        );
        const userRegisters: any = await userRegister({
          name: data.user.displayName,
          email: data.user.email,
          contact: data.user.phoneNumber,
          role: selectedRole,
        });
        console.log('userRegisters', userRegisters);
        if (userRegisters?.access_token) {
          toast.info('There is an already account exists with this email');
        } else {
          toast.success('Account Created Successfully');
        }
        navgate('/login');
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  }

  const handleOptionClick = (option: string) => {
    setSelectedRole(option);
  };

  return (
    <div className="mt-4">
      <div className="justify-content-center align-items-center mt-10 w-100">
        <h3 className="text-center">
          <span>Welcome to our bookings app!</span>
          <br />
          <span>Are you here to list your business or book services?</span>
        </h3>
      </div>
      <div>
        <div className="d-flex justify-content-between w-100">
          <Button
            className={`option-button ${
              selectedRole === 'customer' ? 'bg-primary' : 'bg-white text-dark'
            }`}
            onClick={() => handleOptionClick('customer')}
          >
            I'm a Person looking to book services
          </Button>

          <Button
            className={`option-button ${
              selectedRole === 'admin' ? 'bg-primary' : 'bg-white text-dark'
            }`}
            onClick={() => handleOptionClick('admin')}
          >
            I'm a Business Owner looking to list my buisnesses
          </Button>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="loginForm">
        <h2>Register Here</h2>
        <div className="form-group">
          <input
            placeholder="Enter Your Full Name"
            className="inputWidth"
            {...register('name', {
              required: 'Name is Required',
              minLength: {
                value: 8,
                message: 'Name is Required',
              },
            })}
          />
          {errors.name && (
            <span className="loginErrorMessage">{errors.name.message}</span>
          )}
        </div>
        <div className="form-group">
          <input
            placeholder="Enter Email"
            className="inputWidth"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && (
            <span className="loginErrorMessage">{errors.email.message}</span>
          )}
        </div>
        <div className="form-group">
          <input
            placeholder="Enter Password"
            className="inputWidth"
            type="password"
            {...register('password', {
              required: 'Please Enter Your Password',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },
            })}
          />
          {errors.password && (
            <span className="loginErrorMessage">{errors.password.message}</span>
          )}
        </div>
        <div className="form-group">
          <input
            placeholder="Confirm Password"
            className="inputWidth"
            type="password"
            {...register('confirmPassword', {
              required: 'Please confirm your password',
              validate: (value) =>
                value === getValues('password') || 'Passwords do not match',
            })}
          />
          {errors.confirmPassword && (
            <span className="loginErrorMessage">
              {errors.confirmPassword.message}
            </span>
          )}
        </div>
        <div className="form-group">
          <input
            placeholder="Enter Your Contact Number"
            type="tel"
            className="inputWidth"
            {...register('contact', {
              required: 'Contant No is Required',
              minLength: {
                value: 11,
                message: 'Contact No is Required',
              },
            })}
          />
          {errors.contact && (
            <span className="loginErrorMessage">{errors.contact.message}</span>
          )}
        </div>

        <button type="submit" className="buttonSubmit">
          Sign Up
        </button>
        <div className="BeforeLogin">
          <Button className="google" onClick={googleSignIn}>
            <FcGoogle />
          </Button>
          <Button className="btnBeforeLogin" onClick={facebookSignIn}>
            <LiaFacebookF />
          </Button>
        </div>
        <div className="signUpContainer">
          <span className="signUpLink" onClick={() => navgate('/login')}>
            Click Here To Sign In
          </span>
        </div>
      </form>
    </div>
  );
};

export default SignInForm;
