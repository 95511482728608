import { IDecorModal } from '../interface/banquetDetail.interface';
import { IDecorObject } from '../interface/booking.interface';
import { IVenue, IVenueData, IVenueForm } from '../interface/form.interface';
import { api } from '../utils/axios';

const getHalls = async (id: String) => {
  const response = await api.get<IVenue>(`/guest/venue/GetVenuesOfBanquet/${id}`);

  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};

const createVenue = async (
  id: string,
  VenueData: IVenueForm,
  files: File[]
) => {
  const formData = new FormData();
  files.map((item) => formData.append('images', item));
  Object.keys(VenueData).forEach((key) => {
    //@ts-ignore
    formData.append(key, VenueData[key]);
  });
  console.log('App', formData.get('images'));
  const response = await api.post<IDecorModal>(
    `/venue/createVenue/${id}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};

const editVenue = async (
  id: string,
  data: IVenueForm,
  files: File[],
  pervImages: string[]
) => {
  const formData = new FormData();
  const dummyPrevImages = pervImages.filter((item) => item.startsWith('https'));
  files.map((item) => formData.append('images', item));
  Object.keys(data).forEach((key) => {
    //@ts-ignore
    formData.append(key, data[key]);
  });
  if (!dummyPrevImages.length) {
    console.log('in Prev Images');
    // formData.append('PrevImages', '');
  } else {
    pervImages.forEach(
      (item) => item.startsWith('https') && formData.append('PrevImages', item)
    );
  }

  console.log('Form data: ', formData.getAll);
  const response = await api.post(`/venue/editVenue/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.ok) {
    console.log('Response: ', response.data);
    return response.data;
  } else {
    return null;
  }
};

const addDecorInMenu = async (id: string, decorOption: IDecorObject[]) => {
  const response = await api.patch(`/venue/addDecorInVenue/${id}`, decorOption);

  if (response.ok) {
    console.log('Response For Booking: ', response.data);
    return response.data;
  } else {
    return null;
  }
};
export const hallSvc = {
  getHalls,
  createVenue,
  editVenue,
  addDecorInMenu,
};
