import React, { ChangeEvent, FormEvent, useState } from 'react';
import './index.css';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../service/firebaseConfig';
import { useLocation } from 'react-router-dom';

const ResetPassword = () => {
  const [password, serPassword] = useState('');
  const location = useLocation()
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    serPassword(e.target.value);
  };

  // const location = window.location.search;
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const queryParams = new URLSearchParams(location.search);
    const oobcode: string = queryParams.get('actionCode') || '';
    console.log('Email submitted:', location.search);
    confirmPasswordReset(auth, oobcode, password)
      .then(() => {
        alert('Your password has been reset.');
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  return (
    <div className="mainResetContainer">
      <div className="resetContainer">
        <form onSubmit={handleSubmit}>
          <input
            className="resetInput"
            type="password"
            placeholder="Enter new Password"
            value={password}
            onChange={handleInputChange}
            required
          />
          <button className="resetSubmit" type="submit">
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
