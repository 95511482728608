import React from 'react';
import { CiLogout } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';

const UserHeader = () => {
  const navigation = useNavigate();
  return (
    <>
      <div>
        <header className="customer-screen-header">
          <button className="booking-button" onClick={() => navigation(-1)}>
            GO Back
          </button>
        </header>
      </div>
    </>
  );
};

export default UserHeader;
