import { ChangeEvent, useEffect, useRef, useState } from 'react';
import './index.css';
import { banquetSvc } from '../../service/banquet.service';
import { useNavigate } from 'react-router-dom';
import { cityOptions } from '../../constants/data';
import { IBanquet, Admin } from '../../interface/banquetDetail.interface';
import LoaderSpinner from '../../components/Loader/Loader';
import { mapsSvc } from '../../service/maps.service';
import ChatModal from '../../components/ChatModal/ChatModal';
import { Button } from 'react-bootstrap';
import BeforeLogin from '../../components/Modals/BeforeLogin';
import PrimaryHeader from '../../components/Headers/PrimaryHeader';
import SecondaryHeader from '../../components/Headers/SecondaryHeader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import SearchAndSlideBanner from '../../components/HeaderComponent/SearchAndSlideBanner';
import { IStudio, Studio } from '../../interface/studioDetail.interface';
import { studioSvc } from '../../service/studio.service';

interface CityOption {
  label: string;
  value: string;
}

const PhotographyScreen: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.User);
  const [index, setIndex] = useState<number>(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedCity, setSelectedCity] = useState<null | CityOption>();
  const [loading, setLoading] = useState<boolean>(false);
  const [photography, setPhotography] = useState<IStudio>();
  const [searchPhotography, setSearchPhotography] = useState<string>('');
  const searchTimeRef = useRef<NodeJS.Timeout | null>(null);
  const [position, setPosition] = useState<{
    latitude: number;
    longitude: number;
  }>({ latitude: 11, longitude: 11 });
  const [isChecked, setIsChecked] = useState<number>(0);
  const [chatModalView, setChatModalView] = useState<boolean>(false);
  const [adminIds, setAdminIds] = useState<Admin[]>();
  const [banquetId, setBanquetId] = useState<string>('');
  const [banquetName, setBanquetName] = useState<string>('');
  const closeChatModal = () => {
    setChatModalView(false);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchPhotography(event.target.value);
    if (searchTimeRef.current) {
      clearTimeout(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(async () => {
      handleSearch('', 0, selectedCity?.value, search);
    }, 300);
  };

  const onRangeChange = async (range: number, city: string = '') => {
    console.log('range', range);
    try {
      setLoading(true);
      setSelectedCity(null);
      setIsChecked(range);

      if (range !== 0) {
        // searchTimeRef.current = setTimeout(async () => {
        let latitude: number = 0,
          longitude: number = 0;
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async (position) => {
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
            console.log(latitude, longitude);
            city = await mapsSvc.getCityFromCoordinates(latitude, longitude);

            const resp = await studioSvc.getStudios(
              city,
              latitude,
              longitude,
              range,
              searchPhotography
            );
            if (resp) {
              setPhotography(resp as IStudio);
            }
            setLoading(false);
          });
        }
        // }, 300);
      } else {
        const resp = await studioSvc.getStudios(
          city,
          0,
          0,
          range,
          searchPhotography
        );
        if (resp) {
          setPhotography(resp as IStudio);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const onClearInput = () => {
    setSearchPhotography('');
    handleSearch('', 0, selectedCity?.value, '');
    // getData("");
  };

  const navigation = useNavigate();

  const handleCityChange = async (selectedCity: CityOption) => {
    if (selectedCity) {
      const { value, label } = selectedCity;
      setSelectedCity(selectedCity);
      setIsChecked(0);
      const resp = await studioSvc.getStudios(
        value,
        0,
        0,
        0,
        searchPhotography
      );
      setPhotography(resp as IStudio);
    }
  };

  const handleSearch = async (
    value: string,
    range: number = 0,
    city: string = '',
    searchPhotography: string = ''
  ) => {
    try {
      setLoading(true);
      //   setIsChecked(0);
      if (searchTimeRef.current) {
        clearTimeout(searchTimeRef.current);
      }
      searchTimeRef.current = setTimeout(async () => {
        let latitude: number = 0,
          longitude: number = 0;
        //   if (navigator.geolocation) {
        const resp = await studioSvc.getStudios(
          city,
          latitude,
          longitude,
          range,
          searchPhotography
        );
        setPhotography(resp as IStudio);

        //   }
      }, 300);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch('', 0, selectedCity?.value, searchPhotography);
  }, []);

  return (
    <>
      {loading && <LoaderSpinner />}
      <PrimaryHeader />
      <SecondaryHeader />
      <SearchAndSlideBanner
        cityOptions={cityOptions}
        searchBanquets={searchPhotography}
        isChecked={isChecked as any}
        index={index}
        handleCityChange={handleCityChange}
        onInputChange={onInputChange}
        onClearInput={onClearInput}
        onRangeChange={onRangeChange}
        handleSelect={handleSelect}
        bannerText="Explore Photography Studios"
      />
      <section className="data-list">
        {photography?.studios?.map((studio: Studio) => (
          <div className="data-box" key={studio.ID}>
            <img
              src={
                'https://mobile-cuisine.com/wp-content/uploads/2022/03/wedding-table-gf805441aa_1280.jpg'
              }
              alt=""
              className="data-box-image"
            />
            <h3 className="data-box-h3">{studio.Name}</h3>
            <p>
              Location: {studio.Location.City}, {studio.Location.Area}
            </p>
            <p>Description: {studio.Description}</p>
            <div>
              <BeforeLogin
                show={modalShow}
                onHide={() => setModalShow(false)}
                banquetId={banquetId}
                banquetName={banquetName}
              />
            </div>
          </div>
        ))}
      </section>
      {chatModalView && (
        <div className="modal-backdrop-default">
          <ChatModal admins={adminIds as Admin[]} closeChat={closeChatModal} />
        </div>
      )}
      <footer></footer>
    </>
  );
};

export default PhotographyScreen;
