import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IoChevronBack, IoSend } from 'react-icons/io5';
import { addDataToArrayFirebase } from '../../service/firebaseService.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import moment from 'moment';
import { IHeaderInfo } from '../../interface/chat.interface';
import { toast } from 'react-toastify';
import './index.css';

interface IProps {
  chatId: string;
  headerInfo: IHeaderInfo;
}
const ChatBox = (props: IProps) => {
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const selectedRole = localStorage.getItem('selectedRole');
  const { chatId, headerInfo } = props;
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState<any>();
  const user = useSelector((state: RootState) => state.auth.User);

  const db = getFirestore();

  const handleSendMessage = async () => {
    try {
      const messageTemp = message;
      setMessage('');
      console.log('messages', messages);

      if (messageTemp !== '') {
        await addDataToArrayFirebase(chatId, {
          message: messageTemp,
          sender: user?.id,
          time: Date.now(),
          name: user?.name,
          admin: selectedRole === 'admin',
        });

        if (selectedRole === 'admin') {
          user?.banquet_ids?.forEach(async (id: string) => {
            try {
              // Update document in Firestore
              await updateDoc(doc(db, 'listing', id), {
                [chatId + '.lastMessage']: {
                  messageTemp,
                },
                [chatId + '.date']: Date.now(),
              });
              console.log('Document updated successfully for banquet ID:', id);
            } catch (error) {
              console.error(
                'Error updating document for banquet ID',
                chatId,
                ':',
                error
              );
            }
          });
        } else {
          console.log('header', headerInfo.banquetId);
          await updateDoc(doc(db, 'listing', headerInfo.banquetId as string), {
            [chatId + '.lastMessage']: {
              messageTemp,
            },
            [chatId + '.date']: Date.now(),
          });
        }
        console.log('uid', headerInfo.uid);

        await updateDoc(doc(db, 'listing', headerInfo.uid as string), {
          [chatId + '.lastMessage']: {
            messageTemp,
          },
          [chatId + '.date']: Date.now(),
        });
      } else {
        toast.error('Please Write Message First');
      }
    } catch (error) {
      console.error('Error while sending message: ', error);
    }
  };

  useEffect(() => {
    if (chatId !== '') {
      const unSub = onSnapshot(doc(db, 'messages', chatId), (doc) => {
        if (doc.exists()) {
          console.log('doc', doc?.data().messages);
        }

        doc.exists() && setMessages(doc.data().messages);
      });

      return () => {
        unSub();
      };
    }
  }, [chatId]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  // console.log(messages)
  return (
    <>
      <div className="position-relative vh-100 pb-3">
        <div className="cahtHeaders">
          <IoChevronBack />
          <div className="d-flex align-items-center gap-2">
            <p className="fw-semibold mb-0">
              {selectedRole === 'admin'
                ? headerInfo.displayName
                : headerInfo.displayBanquet}
            </p>
            <img className="personIcon" src="/images/person.png" alt="person" />
          </div>
        </div>
        <div
          className="ChatBox"
          style={{ maxHeight: '85vh', overflowY: 'auto' }}
          ref={chatBoxRef}
        >
          {' '}
          {/* Added style for scrollbar */}
          {messages &&
            messages?.map((message: any) => (
              <div className="conversationBox">
                {message?.sender === user?.id ? (
                  <div className="recievedMsg">
                    {/* <p>
                      {" "}
                      {message.admin ? "Admin" : "Customer"}-{message?.name}
                    </p> */}
                    <span className="senderInfo">You</span>

                    <p className="recievedText">{message?.message}</p>
                    <p className="timestamp">
                      {moment.utc(message.time).fromNow()}
                    </p>
                    {/* <p className="recievedText">i am fine</p> */}
                  </div>
                ) : (
                  <div className="sendMsg">
                    <p className="senderInfo">
                      {' '}
                      {message.admin ? 'Admin' : 'Customer'}
                    </p>
                    <p className="sendText">{message?.message}</p>
                    <p className="timestampSender">
                      {moment.utc(message.time).fromNow()}
                    </p>
                  </div>
                )}
              </div>
            ))}
          <div className="Chathere w-full d-flex align-items-center pb-3">
            <input
              className="rounded-2 px-3 py-1 w-100"
              type="text"
              placeholder="Chat here"
              value={message} // Bind the input value to the message state
              onChange={(event) => setMessage(event.target.value)} // Handle input changes
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleSendMessage();
                }
              }}
            />
            <Button
              className="send-msg bg-transparent border-0"
              onClick={handleSendMessage}
            >
              <IoSend />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBox;
