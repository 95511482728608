import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FcGoogle } from "react-icons/fc";
import { LiaFacebookF } from "react-icons/lia";
import { signInWithFb } from "../../service/firebaseAuthService.service";
import { authLogin, authLoginSocial } from "../../service/auth.service";
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../service/firebaseConfig";
import { ILoginForm } from "../../interface/form.interface";
import { useNavigate } from "react-router-dom";
interface BeforeLogin {
  show: boolean;
  onHide: () => void;
  banquetId?:string;
  banquetName?:string;
}



const BeforeLogin = (props: BeforeLogin) => {

  const navigate = useNavigate()
  const onSubmit = async (data: ILoginForm) => {
    console.log(data);
  
    await signInWithFb(data)
    await authLogin(data);

  };

  const onHallsShow = (id: string) => {
    navigate(`/halls`, { state: { banquetId: id } });
  };

  const handleBanquetChat = (data: string, name: string) => {
    navigate("/Conversation", {
      state: { banquetId: data, banquetName: name },
    });
  };
  
  async function authWithFacebook() {
    try {
      const provider = new FacebookAuthProvider();
      const result:any = await signInWithPopup(auth, provider);
  
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential:any = FacebookAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      await authLoginSocial({token :token})
      // storeFirebaseTokens(result?._tokenResponse?.refreshToken)
      console.log("Access token: ", token);
      return { isLoggedIn: true, token };
    } catch (error) {
      console.log("Error: ", error);
      return { isLoggedIn: false, error };
  
    }
  }

  function googleSignIn(){
    signInWithPopup(auth,provider).then((data:any)=>{
      authLoginSocial({token :data?._tokenResponse.idToken})
      handleBanquetChat(props?.banquetId as string, props?.banquetName as string)
    }).catch((error:any)=>{console.log('error',error)})
  }

  return (
    <>
      <div>
        <Modal
          className="BeforeLogin"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Please login Before chat
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="BeforeLogin">
              <Button className="btnBeforeLogin" onClick={googleSignIn}>
                <FcGoogle />
                Continue with Google
              </Button>
              {/* <Button className="btnBeforeLogin" onClick={authWithFacebookA}>
                <LiaFacebookF />
                Continue with Facebook
              </Button> */}
              <Button className="btnBeforeLogin">Continue as Admin</Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default BeforeLogin;
