import React from 'react';
import { Button } from 'react-bootstrap';
import { IoChatboxOutline } from 'react-icons/io5';
import { IBanquet } from '../../interface/banquetDetail.interface';
import { useNavigate } from 'react-router';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

interface IProps {
  banquet: IBanquet;
}

export const BanquetCard = (props: IProps) => {

  console.log('banquet',props.banquet)
  const navigation = useNavigate();
  const user = useSelector((state: RootState) => state.auth.User);

  const { banquet } = props;
  const onHallsShow = (id: string, name: string,banquet:IBanquet) => {
    navigation(`/halls`, { state: { banquetId: id, banquetName: name , banquet: banquet} });
  };

  const handleBanquetChat = (id: string, name: string) => {
    if (user?.id) {
      navigation('/Conversation', {
        state: { banquetId: id, banquetName: name },
      });
    } else {
      toast.info('Please Login First');
      navigation('/login');
    }
  };
  return (
    <>
      <div className="BanquetCard">
        <img
          className="banquetimg"
          src={
            banquet?.Images?.[0] ||
            'https://mobile-cuisine.com/wp-content/uploads/2022/03/wedding-table-gf805441aa_1280.jpg'
          }
          alt="Banquet"
        />
        <div className="CardBody">
          <h2>{banquet.Name}</h2>
          <div className="d-flex align-items-center gap-2 mb-4">
            <span className="dtltag">
              <strong>{banquet.NumberOfHalls}</strong>Halls
            </span>
            <span className="dtltag">
              <strong>{banquet.ParkingCapacity}</strong>Parkiing
            </span>
          </div>
          <h3 className="Price">
            <span>Starting from</span>
            PKR {banquet.MinPrice}
          </h3>
          <h3 className="Price">
            <span>Location</span>
            {banquet.Location.City}, {banquet.Location.Area}
          </h3>

          <div className="actionArea d-flex align-items-center gap-3">
            <Button
              className="btnRgstr w-100"
              onClick={() => onHallsShow(banquet.ID, banquet.Name,banquet)}
            >
              Venues
            </Button>
            <Button
              className="btnRgstr d-flex align-items-center gap-2"
              onClick={() => handleBanquetChat(banquet.ID, banquet.Name)}
            >
              <IoChatboxOutline />
              Chat
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
