import { ENV } from '../env/enviornmentConstants';
import { api } from '../utils/axios';
import { authApi } from './auth.service';

const getCityArea = async (
  lat: number,
  lng: number
): Promise<{ city: string | undefined; area: string | undefined } | null> => {
  try {
    console.log('In Maps SVC', lat, lng);
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ENV.GOOGLE_API_KEY}`
    );

    if (response.ok) {
      const data = await response.json();
      const addressComponents = data.results[0]?.address_components || [];
      let city, area;
      console.log('In Maps SVC2', addressComponents);

      addressComponents.forEach((component: any) => {
        const componentTypes: string[] = component.types;
        console.log('In Maps SVC2', componentTypes);
        if (componentTypes.includes('locality')) {
          city = component.long_name;
        }
        if (componentTypes.includes('sublocality')) {
          area = component.long_name;
        }
      });

      return { city: city || undefined, area: area || undefined };
    } else {
      console.error('Failed to fetch reverse geocoding data');
      return null;
    }
  } catch (error) {
    console.error('Error fetching reverse geocoding data:', error);
    return null;
  }
};

const getCityFromCoordinates = async (latitude: number, longitude: number) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`
    );

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      if (data.address && data.address.district) {
        console.log(data.address.district);
        const parts = data.address.district.split('District');
        const result = parts[0].trim();
        return result;
      } else {
        return 'City not found';
      }
    } else {
      return 'City not found';
    }
  } catch (error) {
    console.error('Error fetching city:', error);
    return 'Error fetching city';
  }
};

export const mapsSvc = {
  getCityArea,
  getCityFromCoordinates,
};
