import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    signOut,
    sendEmailVerification,
  } from "firebase/auth";
  import { app } from "./firebaseConfig";
  import { getFirestore } from "firebase/firestore";
  import { addDataToFirebase } from "./firebaseService.service";
  
  const auth = getAuth();
  getFirestore(app);
  
  const actionCodeSettings = {
    url: 'http://localhost:3000/', // Your custom verification URL
    handleCodeInApp: true,
};

  export async function signUpWithFb(payload:any) {
    console.log('user',payload)
    try {
      const res : any= await createUserWithEmailAndPassword(
        auth,
        payload.email,
        payload.password,
      );
      sendEmailVerification(res?.user,actionCodeSettings)
      .then(() => {
          console.log("Email verification sent.");
          // Optionally sign out the user after sending verification email
          auth.signOut();
      })
      .catch((error) => {
          console.error("Error sending email verification:", error);
      });
      addDataToFirebase(
        res.uid,
        {
          name: payload.name,
          id: payload.id,
          email: payload.email,
          contact:payload.contact,
          role:payload.role
        },
        "users",
        true
      );
      storeFirebaseTokens(res.user.stsTokenManager.refreshToken);
    } catch (error) {
      console.error("Err while storing user with fb: ", error);
    }
  }
  
  export async function signInWithFb(payload:any) {
    console.log("Payload",payload)
    try {
      const res:any = await signInWithEmailAndPassword(
        auth,
        payload.email,
        payload.password
      );
      storeFirebaseTokens(res.user.stsTokenManager.refreshToken);      
      addDataToFirebase(
        payload.id,
        {
          isOnline: true,
        },
        "users",
        true
      );
  
      return res;
    } catch (error) {
  
      console.error("Err while signin user with fb: ", error);
    }
  }
  
//   export async function authWithGoogle() {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);
//       // The signed-in user info.
//       const credential = GoogleAuthProvider.credentialFromResult(result);
//       const token = credential.accessToken;
//       storeFirebaseTokens(result?._tokenResponse?.refreshToken)
  
//       return { isLoggedIn: true, token };
//     } catch (error) {
//       console.log("Error while signing in with google: ", error);
//       return { isLoggedIn: false, error };
//     }
//   }
  
  export async function authWithFacebook() {
    try {
      const provider = new FacebookAuthProvider();
      const result:any = await signInWithPopup(auth, provider);
  
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential:any = FacebookAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      storeFirebaseTokens(result?._tokenResponse?.refreshToken)
      console.log("Access token: ", token);
      return { isLoggedIn: true, token };
    } catch (error) {
      console.log("Error: ", error);
      return { isLoggedIn: false, error };
  
    }
  }
  
//   export async function refreshFbToken(){
//     const token = sessionStorage.getItem('fbRtToken');
    
//     const data = {
//       grant_type: "refresh_token",
//       refresh_token: token
//     }
//     try {
//       const res = await fetch("https://securetoken.googleapis.com/v1/token?key=AIzaSyBZXaONM2X6yp6qqrzj4445NuvlVFHxDBY",{
//         method: "POST",
//         body: JSON.stringify(data)
//       })
    
//       const jsonData = await res.json();
//       storeFirebaseTokens(jsonData.refresh_token);
      
//     } catch (error) {
//       console.log("Error while refreshing fb token: ",error);
//     }
//   }
  
  export function fbLogOut() {
  
    signOut(auth).then(() => {
      localStorage.clear();
      console.log('logout')
    }).catch((err) => {
      console.log("Error: ",err);
    })
  }
  
  function storeFirebaseTokens(token:string){
    sessionStorage.setItem('fbRtToken', token);
  }
//   function storeFirebaseTokensInLocal(token){
//     localStorage.setItem('fbRtToken', token);
//   }
  