import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ChatBox from '../../components/BanquetChat/ChatBox';
import ChatPeople from '../../components/BanquetChat/ChatPeople';
import { useLocation } from 'react-router-dom';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { IHeaderInfo } from '../../interface/chat.interface';
import UserHeader from '../../components/header/UserHeader';
const Conversation = () => {
  const user = JSON.parse(localStorage.getItem('user')!);
  const [chatId, setChatId] = useState<string>('');
  const [headerIndo, setHeaderInfo] = useState<IHeaderInfo>();

  const location = useLocation();
  const db = getFirestore();
  const fetchUsers = async (userId: string, banquetId: string) => {
    const combinedId =
      userId > banquetId ? userId + banquetId : banquetId + userId;
    try {
      const res = await getDoc(doc(db, 'messages', combinedId));

      if (!res.exists()) {
        await setDoc(doc(db, 'messages', combinedId), { messages: [] });

        await setDoc(
          doc(db, 'listing', userId),
          {
            [combinedId]: {
              uid: user?.id,
              displayName: user?.name,
              displayBanquet: location.state?.banquetName,
              banquetId: banquetId,
              lastMessage: '',
            },
          },
          { merge: true }
        );

        await setDoc(
          doc(db, 'listing', banquetId),
          {
            [combinedId]: {
              banquetId: banquetId,
              displayName: user?.name,
              displayBanquet: location.state?.banquetName,
              lastMessage: '',
              uid: user?.id,
            },
          },
          { merge: true }
        );
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (!location.state) {
      return;
    } else {
      console.log('location', location.state?.banquetId);
      fetchUsers(user?.id as string, location.state?.banquetId);
    }
  }, []);
  console.log('setchat', chatId);
  return (
    <>
      {/* <div className="position-sticky top-0 header-index">
        <UserHeader />
      </div> */}
      <Row className="m-0">
        <Col lg="3">
          <ChatPeople setChatId={setChatId} setHeaderInfo={setHeaderInfo} />
        </Col>
        <Col lg="9">
          {chatId ? (
            <ChatBox chatId={chatId} headerInfo={headerIndo as IHeaderInfo} />
          ) : (
            <div>Select Chat</div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Conversation;
