import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { hallSvc } from '../../service/hall.service';
import { IVenueForm } from '../../interface/form.interface';
import { useForm } from 'react-hook-form';
import { ENV } from '../../env/enviornmentConstants';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { FacilitiesOption, slotsOption } from '../../constants/data';
import './index.css';
import DecorModal from '../../components/DecorModal/DecorModal';

const NewVenue = () => {
  const locationState = useLocation();
  const { banquetId, hallData } = locationState.state || {};
  console.log(banquetId);
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const [decorModalView, setDecorModalView] = useState<boolean>(false);
  const [venueId, setVenueId] = useState<string>('');

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<IVenueForm>();
  const onSubmit = async (data: IVenueForm) => {
    if (selectedImages?.length < 1) {
      setImageError(true);
      return;
    }
    if (hallData.ID) {
      await hallSvc.editVenue(
        hallData.ID,
        data,
        uploadedImages,
        selectedImages
      );
    } else {
      const resposne = await hallSvc.createVenue(
        banquetId,
        data,
        uploadedImages
      );
      console.log('Creattion Response', resposne);
      if (resposne?.createdHall.ID) {
        setDecorModalView(true);
        setVenueId(resposne?.createdHall.ID);
      }
    }
    console.log(data);
    reset();
  };
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    let allImages: File[] = [];
    let viewImages: string[] = [];
    if (event.target.files) {
      const files = Array.from(event.target.files);

      if (selectedImages?.length + files.length > ENV.MAX_FILES_ALLOWED) {
        alert(`You can select a maximum of ${ENV.MAX_FILES_ALLOWED} files.`);
        event.preventDefault();
        event.target.files = null;
      } else {
        const imagesArray = files.map((file) => URL.createObjectURL(file));

        viewImages = Array.isArray(selectedImages)
          ? [...selectedImages, ...imagesArray]
          : imagesArray;
        allImages = [...uploadedImages, ...files];
        console.log('All Images: ', allImages);
        setUploadedImages(allImages);
        setSelectedImages(viewImages);
        setImageError(false);
      }
    }
  };
  const removeImage = (index: number) => {
    const updatedImages = [...selectedImages];
    const updatedFiles = [...uploadedImages];
    console.log('Before selected Images', updatedImages);
    console.log('Before Uploaded Files', updatedFiles);
    console.log('Index Number', index);
    const filesIndex = index - 1;
    if (selectedImages[index].startsWith('https')) {
      updatedImages.splice(index, 1);
    } else if (selectedImages.length === updatedFiles.length) {
      updatedImages.splice(index, 1);
      updatedFiles.splice(index, 1);
    } else {
      updatedImages.splice(index, 1);

      updatedFiles.splice(filesIndex, 1);
    }
    console.log('After selected Images', updatedImages);
    console.log('After Uploaded Files', updatedFiles);
    setSelectedImages(updatedImages);
    setUploadedImages(updatedFiles);
  };
  const handleFacilitiesChange = (data: any) => {
    console.log(data);
    const selectedData: string[] = data.map((item: any) => item.value);
    console.log(selectedData);
    if (selectedData.length > 0) {
      clearErrors('facilities');
    } else {
    }
    setValue('facilities', selectedData);
  };
  const handleSlotsChange = (data: any) => {
    console.log(data);
    const selectedData: string[] = data.map((item: any) => item.value);
    console.log(selectedData);
    if (selectedData.length > 0) {
      clearErrors('slots');
    } else {
    }
    setValue('slots', selectedData);
  };

  useEffect(() => {
    if (hallData) {
      setSelectedImages(hallData?.Images);
      setValue('facilities', hallData.Facilities);
      setValue('slots', hallData.Slots);
      setValue('type', hallData.Type);
    } else {
      setValue('facilities', ['catering-services']);
      setValue('slots', ['lunch']);
    }
  }, []);
  return (
    <>
      <header className="customer-screen-header bg-body-tertiary">
        <button className="booking-button" onClick={() => navigate(-1)}>
          Go Back
        </button>
      </header>
      <div className="NewBanquetBooking">
        <h3>{hallData ? 'Edit' : 'New'} Venue Details</h3>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="validationCustom01" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Enter Name"
              defaultValue={hallData?.Name}
              {...register('name', {
                required: 'Venue Name is Required',
                minLength: {
                  value: 5,
                  message: 'Venue Name is Required',
                },
              })}
            />
            {errors.name && (
              <span className="loginErrorMessage">{errors.name.message}</span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              required
              type="number"
              placeholder="Enter Floor No"
              defaultValue={hallData?.Floor}
              {...register('floor', {
                required: 'Floor Number in Required',
                min: {
                  value: 1,
                  message: 'Floor Number should be greater than or equal to 1',
                },
              })}
            />
            {errors.floor && (
              <span className="loginErrorMessage">{errors.floor.message}</span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              required
              placeholder="Enter Minimum Number of Peoples"
              type="number"
              defaultValue={hallData?.MinCapacity}
              {...register('minCapacity', {
                required: 'Number of People is required',
                min: {
                  value: 1,
                  message:
                    'Number of People should be greater than or equal to 1',
                },
              })}
            />
            {errors.minCapacity && (
              <span className="loginErrorMessage">
                {errors.minCapacity.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              required
              type="number"
              placeholder="Enter Maximum Number of Peoples"
              defaultValue={hallData?.MaxCapacity}
              {...register('maxCapacity', {
                required: 'Maximum Limit is required',
                min: {
                  value: 1,
                  message: 'Maximum Limit is required',
                },
                validate: (value: number) => {
                  //@ts-ignore
                  const minCapacity = parseInt(getValues('minCapacity'), 10);
                  if (isNaN(minCapacity) || value < minCapacity) {
                    return 'Maximum Limit must be greater than Minimum Capacity';
                  }
                },
              })}
            />
            {errors.maxCapacity && (
              <span className="loginErrorMessage">
                {errors.maxCapacity.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              required
              placeholder="Enter Base Price"
              type="number"
              defaultValue={hallData?.BasePrice}
              {...register('basePrice', {
                required: 'Base Price is required',
                min: {
                  value: 1,
                  message: 'Base Price should be greater than or equal to 1',
                },
              })}
            />
            {errors.basePrice && (
              <span className="loginErrorMessage">
                {errors.basePrice.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              required
              placeholder="Enter Chicken Price"
              type="number"
              defaultValue={hallData?.ChickenPrice}
              {...register('chickenPrice', {
                required: 'Chicken Price is required',
                min: {
                  value: 1,
                  message: 'Chicken Price should be greater than or equal to 1',
                },
              })}
            />
            {errors.chickenPrice && (
              <span className="loginErrorMessage">
                {errors.chickenPrice.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              required
              placeholder="Enter Mutton Price"
              type="number"
              defaultValue={hallData?.MuttonPrice}
              {...register('muttonPrice', {
                required: 'Mutton Price is required',
                min: {
                  value: 1,
                  message: 'Mutton Price should be greater than or equal to 1',
                },
              })}
            />
            {errors.muttonPrice && (
              <span className="loginErrorMessage">
                {errors.muttonPrice.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Select
              {...register('facilities', {
                required: 'Type of facilities is required',
                min: {
                  value: 1,
                  message: 'At least one option must be checked.',
                },
              })}
              options={FacilitiesOption}
              isMulti
              defaultValue={[FacilitiesOption[0]]}
              onChange={handleFacilitiesChange}
            />
            {errors.facilities && (
              <span className="loginErrorMessage">
                {errors.facilities.message}
              </span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Select
              {...register('slots', {
                required: 'Slots is required',
                min: {
                  value: 1,
                  message: 'At least one option must be checked.',
                },
              })}
              options={slotsOption}
              isMulti
              defaultValue={[slotsOption[0]]}
              onChange={handleSlotsChange}
            />
            {errors.slots && (
              <span className="loginErrorMessage">{errors.slots.message}</span>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <select
              {...register('type')}
              className="selectOption"
              defaultValue={hallData?.Type}
            >
              <option value="hall" className="form-label p-5">
                Hall
              </option>
              <option value="marquee" className="form-label">
                Marquee
              </option>
              <option value="open-space" className="form-label">
                Open Space
              </option>
            </select>
          </Form.Group>
          <Form.Group>
            <div className="imageContainer">
              {selectedImages?.map((image, index) => (
                <div key={index}>
                  <img alt={`image-${index}`} width={'150px'} src={image} />
                  <br />
                  <button
                    className="removeButton"
                    onClick={() => removeImage(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
            <div className="form-group inputmaxWidth">
              <label
                className={`${
                  selectedImages?.length >= 6
                    ? 'fileInputLabelDisabled'
                    : 'fileInputLabel'
                }`}
              >
                <input
                  type="file"
                  name="myImage"
                  onChange={handleImageChange}
                  multiple
                  className="fileInput"
                  accept="image/*"
                  disabled={selectedImages?.length >= 6}
                />
                Upload Image
              </label>
              {imageError && (
                <span className="loginErrorMessage">Image is Required</span>
              )}
            </div>
          </Form.Group>
          <Button className="buttonSubmit" variant="primary" type="submit">
            {hallData?.ID ? 'Edit' : 'Create'} Venue
          </Button>
        </Form>
        {decorModalView && (
          <div className="modal-backdrop-default">
            <DecorModal
              closeModal={() => {
                setDecorModalView(false);
                navigate(-1);
              }}
              venueId={venueId}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default NewVenue;
